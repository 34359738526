import { getStorageValue, removeStorageValue, setStorageValue } from '@portal-internet/core';

const LayoutChangesTmpStorageService = {
    _prefix: 'ccma_webapp_',
    storageKey: 'layoutChanges',

    _generateKey() {
        return `${this._prefix}${this.storageKey}`;
    },

    get() {
        getStorageValue(this._generateKey());
    },

    save(layoutState) {
        setStorageValue(this._generateKey(), layoutState);
    },

    clear() {
        removeStorageValue(this._generateKey());
    }
};

export default LayoutChangesTmpStorageService;
