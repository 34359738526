import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import { useUser } from 'hooks/useUser';
import { typesViewport, useViewport } from 'hooks/useViewport';
import __filter from 'lodash/filter';
import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import styles from './menu.module.scss';

export default function MenuLogin(props) {
    const { requiredServices } = props;
    const { isAuthenticated, inicial, name, signUp, signOut, editProfileCP, changePassCP, changeEmail, deleteMyAccount } =
        useUser();
    const [openUsuari, setOpenUsuari] = useState(false);
    const refUsuari = useRef();
    const { viewport } = useViewport();

    const stylesUsuari = clsx({
        [styles.dropdown]: true,
        [styles.menuUsuari]: true,
        [styles.open]: openUsuari
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refUsuari?.current?.contains(event.target)) {
                setOpenUsuari(false);
            }
        };
        document.addEventListener('click', handleClickOutside, { passive: true });
        document.addEventListener('touchstart', handleClickOutside, { passive: true });

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [refUsuari]);

    const loginAvailable = __filter(requiredServices?.servicesAvailability, function (servei) {
        return servei.login;
    });

    return loginAvailable.length > 0 ? (
        isAuthenticated ? (
            <div ref={refUsuari}>
                <button
                    className={styles.botoUsuari}
                    onClick={() => setOpenUsuari(!openUsuari)}
                    data-testid='menu-boto-usuari-autenticat'
                >
                    <span>{inicial.toUpperCase()}</span>
                </button>
                <div className={stylesUsuari}>
                    <button className={styles.tanca} onClick={() => setOpenUsuari(false)}>
                        <Icona icona='tanca' mida={viewport === typesViewport.VP_MOBILE ? 26 : 16} alt='Tanca' />
                    </button>
                    <div className={styles.nom}>{name}</div>
                    <ul>
                        <li>
                            <Enllac className={styles.opcio_usuari} href='/3cat/meva-llista/'>
                                la meva llista
                            </Enllac>
                        </li>
                        <li>
                            <button onClick={editProfileCP}>
                                <span className={styles.opcio_usuari}>edita perfil</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={changePassCP}>
                                <span className={styles.opcio_usuari}>canvia la contrasenya</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={changeEmail}>
                                <span className={styles.opcio_usuari}>canvia l&apos;adreça electrònica</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={deleteMyAccount}>
                                <span className={styles.opcio_usuari}>dona&apos;t de baixa</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={signOut} className={styles.contOpcioIcona} data-testid='menu-boto-tancar-sessio'>
                                <span className={styles.opcio_usuari}>tanca sessió</span>
                                <span className={styles.ico_sortir}>
                                    <Icona icona='sortida' mida='15' alt='icona tancar sessió' />
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        ) : (
            <button className={styles.botoLogin} onClick={() => signUp()} data-testid='menu-boto-login'>
                <Icona icona='usuari' className={styles.ico_usuari} alt='Fes login' inline={true} />
            </button>
        )
    ) : (
        <></>
    );
}
