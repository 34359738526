import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import styles from './menu.module.scss';

const _buildEnllac = (slugPrograma, element, i) => {
    const stylesLink = clsx({
        [styles.linkSeccio]: true
    });
    let route = {};
    if (element.type === 'web_3cat_programa_seccio') {
        route = {
            type: 'web_3cat_programa_seccio',
            slug: slugPrograma,
            slugRelacio: element.slug
        };
    } else {
        route = {
            type: 'web_3cat_programa_seccio_item',
            id: element.id,
            slugPrograma: slugPrograma,
            slug: element.nom_seccio
        };
    }
    return (
        <li key={i} className={styles.menuItemSeccio}>
            <Enllac className={stylesLink} route={route}>
                <span className={styles.itemSeccio}>{element.nom_seccio}</span>
                <Icona className={styles.iconaSeccio} icona='fletxa_dreta' mida={12} />
            </Enllac>
        </li>
    );
};

const MenuSeccions = (props) => {
    const { items, slugPrograma } = props;
    const itemProgramesSencers = {
        nom_seccio: 'Programes sencers',
        slug: 'ultims-programes',
        type: 'web_3cat_programa_seccio'
    };

    const itemsMenu = [itemProgramesSencers, ...items];

    const _iterateItems = (itemsMenu) => {
        let dinamicLinks = null;
        if (!__isEmpty(itemsMenu)) {
            dinamicLinks = itemsMenu.map((element, i) => _buildEnllac(slugPrograma, element, i));
        }
        return dinamicLinks;
    };

    return (
        <>
            <div>
                <ul className={styles.seccionsWebview}>{_iterateItems(itemsMenu)}</ul>
            </div>
        </>
    );
};

export default MenuSeccions;
