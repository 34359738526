const dismissedErrors = [
    "Script error.",
    "Uncaught TypeError: Cannot read property '[object Array]' of null",
    "Uncaught TypeError: Cannot read properties of null",
    "SecurityError: Failed to execute '' on 'TeatroV8GenericImpl': SecurityError",
    "TypeError: e is not a constructor",
    "Uncaught TypeError: window.getWinOwnerAppId is not a function",
    "ResizeObserver loop limit exceeded",
    "TypeError: Cannot read property 'allData' of undefined",
    "ReferenceError: $ is not defined",
    "errorMsg=ResizeObserver loop completed with undelivered notifications"
];

const shouldSendLog = (error) => {
    return !dismissedErrors.includes(error);
};

export { shouldSendLog };
