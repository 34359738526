import { UserDelivertyContext } from 'context/UserDelivertyProvider';
import { useContext } from 'react';

export const useDelivertyUser = () => {
    const {
        userData,
        loginUser,
        isEditModeEnable
    } = useContext(UserDelivertyContext);

    return {
        userData,
        loginUser,
        isEditModeEnable
    };
};
