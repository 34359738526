import { useEffect, useState } from 'react';
import { useModal } from 'hooks/3catinfo/useModal';
import Icona from 'components/3catinfo/ui/Icona';
import clsx from 'clsx';
import styles from './modal.module.scss';

const Controls = (props) => {
    const { optionsModal, onClickClose } = props;

    if (!optionsModal?.showControls) return <></>;
    return (
        <div className={styles.controls}>
            <div className={styles.controlsInner}>
                <button onClick={onClickClose}>
                    <Icona className={styles.iconaTancar} icona='tanca' alt='Icona tanca' mida='32' isBlanc={true} />
                </button>
            </div>
        </div>
    );
};

const Modal = (props) => {
    const { openModal, modalContent, optionsModal, removeModal } = useModal();
    const [topContentScroll, setTopContentScroll] = useState(0);

    const styleModal = clsx({
        [styles.modal]: true,
        [styles[optionsModal?.mode]]: !!optionsModal?.mode,
        [styles[optionsModal?.style]]: !!optionsModal?.style,
        [optionsModal.className]: !!optionsModal?.className
    });

    useEffect(() => {
        if (optionsModal.mode !== 'full-screen') setTopContentScroll(window.scrollY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    const onClickClose = () => {
        if (optionsModal?.onClickClose) {
            optionsModal.onClickClose();
        } else {
            removeModal();
        }
    };

    return openModal ? (
        <div className={styleModal} id='container-modal' style={{ top: topContentScroll }}>
            <Controls optionsModal={optionsModal} onClickClose={onClickClose} />
            <div className={styles.inner} id='content-modal'>
                {modalContent}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Modal;
