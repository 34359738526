import Menu from './Menu';
import MenuSeccions from './Menu.seccions';
import MenuBarra from './Menu.barra';

export default function Component(props) {
    const { tipus, isWebviewActive, nombonic } = props;
    if (isWebviewActive && tipus !== 'webviewSeccions') {
        return <></>;
    } else if (tipus === 'webviewSeccions') {
        return <MenuSeccions {...props} />;
    } else if (tipus === 'menuBarra') {
        return <MenuBarra />;
    }
    if (nombonic === 'pellicules') props = { ...props, seccio: 'pellicules' };
    return <Menu {...props} />;
}
