import Boto3Cat from './../../3cat/ui/Boto';
import Boto3CatInfo from './../../3catinfo/Boto';
import { useRouter } from 'next/router';

export default function Component(props) {
    const router = useRouter();

    const isNoticies = router.asPath.indexOf('/3catinfo/') === 0;
    return isNoticies ? <Boto3CatInfo {...props} /> : <Boto3Cat {...props} />;
}
