const getCropByViewport = (viewport) => {
    switch (viewport) {
        case 'VP_MOBILE':
            return 670;
        case 'VP_SMALL':
            return 670;
        case 'VP_REGULAR':
            return 1014;
        case 'VP_LARGE':
            return 1920;
        default:
            return 1920;
    }
};

const getImatgeCropped = (imatge, viewport) => {
    if (imatge !== undefined) {
        return imatge.slice(0, -4) + '_' + getCropByViewport(viewport) + imatge.slice(-4);
    } else {
        return imatge;
    }
};

export { getCropByViewport, getImatgeCropped };
