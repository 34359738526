import { useEffect, useState } from 'react';

export const typesViewport = {
    VP_MOBILE: 'VP_MOBILE',
    VP_SMALL: 'VP_SMALL',
    VP_REGULAR: 'VP_REGULAR',
    VP_LARGE: 'VP_LARGE'
};

export const useViewport = () => {
    const [viewport, setViewport] = useState(typesViewport.VP_MOBILE);
    const [isMobileDevice, setIsMobileDevice] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const [isOnlyMobile, setIsOnlyMobile] = useState(true);

    const updateViewPort = () => {
        let _viewport;
        if (window.innerWidth < 720) {
            _viewport = typesViewport.VP_MOBILE;
        } else if (window.innerWidth < 1023) {
            _viewport = typesViewport.VP_SMALL;
        } else if (window.innerWidth < 1679) {
            _viewport = typesViewport.VP_REGULAR;
        } else {
            _viewport = typesViewport.VP_LARGE;
        }
        setViewport(_viewport);
    };

    useEffect(() => {
        setIsMobile([typesViewport.VP_MOBILE, typesViewport.VP_SMALL].includes(viewport));
        setIsOnlyMobile(viewport === typesViewport.VP_MOBILE);
    }, [viewport]);

    useEffect(() => {
        window.addEventListener('resize', updateViewPort);
        setIsMobileDevice(/Mobi|Android/i.test(navigator.userAgent));
        updateViewPort();
        return () => window.removeEventListener('resize', updateViewPort);
    }, []);

    return {
        viewport,
        isMobile,
        isOnlyMobile,
        isMobileDevice
    };
};
