import { useRouter } from 'next/router';
import { createContext } from 'react';
import { isWebviewPath, isEmbedPath } from 'utils/pathHelper';

export const ProducteContext = createContext();

const _isWebviewActive = () => {
    const router = useRouter();
    return isWebviewPath(router.asPath) || isEmbedPath(router.asPath);
};

const ProducteProvider = (props) => {
    const { children } = props;

    const urlAppPrefix = 'web';
    const isWebviewActive = _isWebviewActive();
    const isTv = false;

    if (typeof document !== 'undefined' && isWebviewActive) {
        document.body.classList.add('webview');
    }

    return <ProducteContext.Provider value={{ urlAppPrefix, isWebviewActive, isTv }}>{children}</ProducteContext.Provider>;
};

export default ProducteProvider;
