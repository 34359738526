import { getDataUTC, isPeli, getSlug, removeHtmlTags, dataResources } from '@portal-internet/core';
import { useProducte } from 'hooks/3cat/useProducte';
import { useRouter } from 'next/router';
import __escape from 'lodash/escape';
import { getImatge } from '@portal-internet/components';

const _getCurrentUrl = (router) => {
    let url = router.asPath,
        indexParams = url.indexOf('?');
    url = indexParams > 0 ? url.substring(0, indexParams) : url;
    return '//' + dataResources.domainCCMA + url;
};
const _getHomeObjectSchema = (router) => {
    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        'name': '3Cat',
        'url': _getCurrentUrl(router),
        'logo': [
            {
                '@type': 'ImageObject',
                'url': 'https://statics.3cat.cat/recursos/3cat/logos/web/3cat_fons.png'
            }
        ]
    };
    return schemaData;
};
const _getNoticiaObjectSchema = (props, router) => {
    const { titol, entradeta, cos, imatges, domini, autors, redactor, dataModificacio, dataPublicacio } = props;
    const getDades = () => {
        let autor = 'Redacció 324';
        let logoUrl = 'https://img.3cat.cat/multimedia/png/0/0/1543404122600.png';
        let logoWidth = 150;
        let logoHeight = 60;
        let logoName = '324';
        let urlPublisher = '//' + dataResources.domainCCMA + '/324';
        switch (domini) {
            case 'MET':
                autor = 'Redacció El temps';
                break;
            case 'PUCR':
            case 'WCR':
                autor = 'Redacció Catalunya Ràdio';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/8/5/1457533676558.jpg';
                logoWidth = 235;
                logoHeight = 101;
                logoName = 'Catalunya Ràdio';
                urlPublisher = '//' + dataResources.domainCCMA + '/catradio';
                break;
            case 'PUS3':
            case 'SP3':
            case 'TAG':
                autor = 'Redacció Super3';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/8/8/1457534811588.jpg';
                logoWidth = 288;
                logoHeight = 201;
                logoName = 'Super3';
                urlPublisher = '//' + dataResources.domainCCMA + '/tv3/super3';
                break;
            case 'PUE3':
            case 'ESP':
                autor = 'Redacció Esport3';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/1/1/1672220093611.jpg';
                logoWidth = 424;
                logoHeight = 250;
                logoName = 'Esport3';
                urlPublisher = '//' + dataResources.domainCCMA + '/esport3';
                break;
            case 'PUCC':
            case 'PUTR':
            case 'CCRTV':
                autor = 'Redacció CCMA';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/4/1/1457534753314.jpg';
                logoWidth = 353;
                logoHeight = 111;
                logoName = 'CCMA';
                urlPublisher = '//' + dataResources.domainCCMA;
                break;
            case 'PUPUB':
            case 'PUSP':
                autor = 'Redacció CCMA';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/8/5/1453905492658.jpg';
                logoWidth = 526;
                logoHeight = 181;
                logoName = 'CCMA';
                urlPublisher = '//' + dataResources.domainCCMA;
                break;
            case 'TVC':
                autor = 'Redacció TV3';
                logoUrl = 'https://img.3cat.cat/multimedia/jpg/6/4/1457533639546.jpg';
                logoWidth = 140;
                logoHeight = 118;
                logoName = 'TV3';
                urlPublisher = '//' + dataResources.domainCCMA + '/tv3';
                break;
        }
        autor = redactor !== '' ? redactor : autor;
        autor = autors ? autors[0].desc : autor;
        const autorDomini = domini === 'PUCR' ? 'catradio' : 'tv3';
        const autorUrl = autors
            ? 'https://' + dataResources.domainCCMA + '/' + autorDomini + '/' + getSlug(autors[0].desc) + '/autor/' + autors[0].id
            : '';

        return {
            autor: autor,
            autorDomini: autorDomini,
            autorUrl: autorUrl,
            logoUrl: logoUrl,
            logoWidth: logoWidth,
            logoHeight: logoHeight,
            logoName: logoName,
            imatge: getImatge(imatges, 'web_destacat', null, 1200),
            urlPublisher: urlPublisher
        };
    };
    const dades = getDades();

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': _getCurrentUrl(router)
        },
        'url': _getCurrentUrl(router),
        'description': entradeta,
        'articleBody': __escape(removeHtmlTags(cos)),
        'publisher': {
            '@type': 'Organization',
            'name': dades.logoName,
            'url': dades.urlPublisher,
            'logo': {
                '@type': 'ImageObject',
                'url': dades.logoUrl,
                'height': dades.logoHeight,
                'width': dades.logoWidth
            }
        },
        'author': [
            {
                '@type': 'Person',
                'name': dades.autor
            }
        ],
        'headline': titol,
        'image': [
            {
                '@type': 'ImageObject',
                'url': dades.imatge,
                'width': 1200,
                'height': 677
            }
        ]
    };
    if (dades.autorUrl) schemaData['author'][0]['url'] = dades.autorUrl;
    if (dataPublicacio) schemaData['datePublished'] = getDataUTC(dataPublicacio);
    if (dataModificacio) schemaData['dateModified'] = getDataUTC(dataModificacio);
    return schemaData;
};

const _getVideoAudioObjectSchema = (props, router) => {
    const {
        type,
        titol,
        entradeta,
        entradetaPromo,
        durada,
        dataCreacio,
        dataModificacio,
        dataCaducitat,
        nomPrograma,
        imatges,
        formats,
        embed
    } = props;

    const getEmbedUrl = () => {
        return `//${dataResources.domainCCMA}/video/embed/${router.query.id}/?tipus_embed=google`;
    };

    const getThumbnail = (posMida = 0) => {
        const arrMides = ['670x378', '426x240', '326x184', '320x180', '200x113', '120x68'];
        if (posMida >= arrMides.length || !imatges)
            return 'https://statics.3cat.cat/recursos/3play/img/videos_recurs_640x360.jpg';
        const imatge = imatges.find(({ mida }) => mida === arrMides[posMida]);
        if (imatge) return imatge.text;
        return getThumbnail(posMida + 1);
    };

    const getDurada = () => {
        const arrDurada = durada.split(':');
        return `PT${arrDurada[0]}H${arrDurada[1]}M${arrDurada[2]}S`;
    };

    const getName = () => {
        const prefix = !isPeli(formats) && nomPrograma ? `${nomPrograma} - ` : '';
        return prefix + titol;
    };

    const schemaData = {
        '@context': 'https://schema.org',
        '@type': type,
        'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': _getCurrentUrl(router)
        },
        'name': getName(),
        'description': entradetaPromo || entradeta,
        'thumbnailUrl': getThumbnail(),
        'uploadDate': getDataUTC(dataCreacio),
        'duration': getDurada(),
        'publisher': {
            '@type': 'Organization',
            'name': '3cat',
            'logo': {
                '@type': 'ImageObject',
                'url': 'https://statics.3cat.cat/recursos/3cat/logos/web/3cat_fons.png',
                'height': 160,
                'width': 355
            }
        },
        'creator': nomPrograma,
        'url': _getCurrentUrl(router)
    };

    if (dataModificacio) schemaData['dateModified'] = getDataUTC(dataModificacio);
    if (dataCaducitat) schemaData['expires'] = getDataUTC(dataCaducitat);
    if (embed) schemaData['embedUrl'] = getEmbedUrl();
    return schemaData;
};

export default function MarcatgeEstructurat(props) {
    const { type } = props;
    const { isWebviewActive } = useProducte();
    const router = useRouter();
    let schemaData = null;

    if (isWebviewActive) return <></>;

    switch (type) {
        case 'HomeObject':
            schemaData = _getHomeObjectSchema(router);
            break;
        case 'VideoObject':
        case 'AudioObject':
            schemaData = _getVideoAudioObjectSchema(props, router);
            break;
        case 'NewsArticle':
            schemaData = _getNoticiaObjectSchema(props, router);
            break;
    }

    return (
        <>
            <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }} />
        </>
    );
}
