export const getLogoDirecte = (canal) => {
    const isOca = canal === 'oca1' || canal === 'oca2' || canal === 'oca3';
    let logo = isOca ? '3cat' : canal?.toLowerCase().replace('_', '').replace('puc', '');
    switch (logo) {
        case 'e3':
            logo = 'esport3';
            break;
        case 'tv3':
            logo = 'tv3';
            break;
        case 'tv3cat':
            logo = 'tv3cat';
            break;
        case '324':
            logo = '324';
            break;
        case '33':
            logo = '33';
            break;
        case 's3':
        case 's3s3':
        case 's3x3':
        case 'sx3':
            logo = 'sx3';
            break;
        case 'cr':
            logo = 'cr';
            break;
        case 'icat':
            logo = 'ic';
            break;
        case 'cm':
            logo = 'cm';
            break;
        case 'ci':
            logo = 'ci';
            break;
        case 'oca1':
        case 'oca2':
        case 'oca4':
            logo = 'oca_3cat';
            break;
        case 'oca3':
            logo = 'pucoca3';
            break;
        case 'crei':
            logo = 'ocr1';
            break;
        case 'fc1':
            logo = 'pucfc1';
            break;
        case 'fc1':
            logo = 'pucfc2';
            break;
        default:
            logo = '3cat';
            break;
    }
    return '/_resources/logos/' + logo + '.svg?v=1.2501.7';
};
