import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
//import Cookies from 'js-cookie';
import { apiConfig } from './apiConfig';
import { msalConfig, tokenRequest } from './authConfig';
import { b2cPolicies } from './policies';

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new PublicClientApplication(msalConfig);

let accountId = '';
let username = '';
let accessToken = null;

async function getAccountFromRedirectPromise() {
    let account;
    let response;
    try {
        response = await myMSALObj.handleRedirectPromise();
    } finally {
        if (response) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
             * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (
                b2cPolicies.authorities.signUpSignInCP.authority
                    .toUpperCase()
                    .includes(response.idTokenClaims['acr'].toUpperCase())
            ) {
                account = response.account;
            }
        }
    }
    return account;
}

async function getAcquireTokenSilentPromise() {
    // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
    const account = myMSALObj.getAllAccounts()[0];

    const accessTokenRequest = {
        scopes: [...apiConfig.b2cScopes],
        forceRefresh: true,
        account: account
    };

    try {
        let accessTokenResponse = await myMSALObj.acquireTokenSilent(accessTokenRequest);
        accessToken = accessTokenResponse.accessToken;
    } catch (error) {
        console.log('acquireTokenSilent error ', error);
        signOut();
    }
    return accessToken;
}

async function selectAccount() {
    /**
     * See here for more information on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    // Set active account on page load
    let myAccount = await getAccountFromRedirectPromise();
    if (!(myAccount && myAccount.tenantId !== '')) {
        const currentAccounts = myMSALObj.getAllAccounts();
        if (currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            const accounts = currentAccounts.filter((account) => account.tenantId !== '');
            if (accounts.length > 1) {
                // localAccountId identifies the entity for which the token asserts information.
                if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
                    // All accounts belong to the same user
                    myAccount = accounts.reduce((account, currentAccount) => {
                        if (account.idTokenClaims.auth_time > currentAccount.idTokenClaims.auth_time) {
                            return account;
                        } else {
                            return currentAccount;
                        }
                    });
                } else {
                    // Multiple users detected. Logout all to be safe.
                    signOut();
                }
            } else if (accounts.length === 1) {
                myAccount = accounts[0];
            }
        } else if (currentAccounts.length === 1) {
            myAccount = currentAccounts[0];
        }
    }

    accountId = myAccount && myAccount.homeAccountId;
    username = myAccount && myAccount.username;

    return myAccount;
}

const setAccount = (account) => {
    accountId = account && account.homeAccountId;
    username = account && account.username;
};

function signOut() {
    //TODO: validem q no cal cookie al 3cat per mantenir la sessió
    // const cookieName = '_ccma_usuari_portal_';
    // Cookies.remove(cookieName);
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    const logoutRequest = {
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
    };

    myMSALObj.logoutRedirect(logoutRequest);
}

function getTokenRedirect(request) {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account = myMSALObj.getAccountByHomeId(accountId);

    /**
     *
     */
    return myMSALObj
        .acquireTokenSilent(request)
        .then((response) => {
            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            if (!response.accessToken || response.accessToken === '') {
                throw new InteractionRequiredAuthError();
            } else {
                accessToken = response.accessToken;
                passTokenToApi();
            }
        })
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return myMSALObj.acquireTokenRedirect(request);
            } else {
                console.log(error);
            }
        });
}

// Acquires and access token and then passes it to the API call
function passTokenToApi() {
    if (!accessToken) {
        getTokenRedirect(tokenRequest);
    } else {
        try {
            callApi(apiConfig.webApi, accessToken);
        } catch (error) {
            console.log(error);
        }
    }
}

/**
 * To initiate a B2C user-flow, simply make a login request using
 * the full authority string of that user-flow e.g.
 * https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2
 */

function editProfileCP() {
    const editProfileRequest = b2cPolicies.authorities.editProfileCP;
    if (accountId) {
        editProfileRequest.loginHint = myMSALObj.getAccountByHomeId(accountId).username;
        myMSALObj.loginRedirect(editProfileRequest);
    } else {
        signUpSignInCP();
    }
}

function changePassCP() {
    const forgotPasswordRequest = b2cPolicies.authorities.forgotPasswordCP;
    if (accountId) {
        forgotPasswordRequest.loginHint = myMSALObj.getAccountByHomeId(accountId).username;
        myMSALObj.loginRedirect(forgotPasswordRequest);
    } else {
        signUpSignInCP();
    }
}

function changeEmail() {
    const changeEmailRequest = b2cPolicies.authorities.changeEmail;
    if (accountId) {
        changeEmailRequest.loginHint = myMSALObj.getAccountByHomeId(accountId).username;
        myMSALObj.loginRedirect(changeEmailRequest);
    } else {
        signUpSignInCP();
    }
}

function deleteMyAccount() {
    const deleteMyAccountRequest = b2cPolicies.authorities.deleteMyAccount;
    if (accountId) {
        deleteMyAccountRequest.loginHint = myMSALObj.getAccountByHomeId(accountId).username;
        myMSALObj.loginRedirect(deleteMyAccountRequest);
    } else {
        signUpSignInCP();
    }
}

async function signUpSignInCP(params = null) {
    const signUpSignInCPRequest = params ? { state: params } : b2cPolicies.authorities.signUpSignInCP;
    myMSALObj.loginRedirect(signUpSignInCPRequest);
}

export {
    getAcquireTokenSilentPromise,
    selectAccount,
    setAccount,
    signUpSignInCP,
    signOut,
    editProfileCP,
    changePassCP,
    changeEmail,
    deleteMyAccount
};
