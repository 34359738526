import dynamic from 'next/dynamic';

export const components = {
    CARRUSSEL: dynamic(() => import(`./CarrusselWrapper`)),
    KEYFRAME: dynamic(() => import(`components/3cat/SliderWrapper`)),
    KEYFRAME_SNS_AV: dynamic(() => import(`components/3cat/KeyframeSenseAvantitolWrapper`)),
    PASTILLA: dynamic(() => import(`components/3cat/SliderWrapper`)),
    POSTER_PETIT: dynamic(() => import(`components/3cat/SliderWrapper`)),
    POSTER_GRAN: dynamic(() => import(`components/3cat/SliderWrapper`)),
    QUADRAT: dynamic(() => import(`components/3cat/SliderWrapper`)),
    SUPERDESTACAT: dynamic(() => import(`./SuperdestacatWrapper`)),
    SUPERDESTACAT_TEMATIC: dynamic(() => import(`./SuperdestacatTematic`)),
    MEGABANNER: dynamic(() => import(`components/3cat/Publicitat`)),
    BILLBOARD: dynamic(() => import(`components/3cat/Publicitat`)),
    MEGABANNERSX1: dynamic(() => import(`components/3cat/Publicitat`)),
    Boto: dynamic(() => import(`components/ui/Boto`))
};
