import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import { formatTypes, types } from 'components/3cat/Formats';
import Boto from 'components/ui/Boto';
import { usePercentatgeVist } from 'hooks/3cat/usePercentatgeVist';
import __findIndex from 'lodash/findIndex';
import __isEmpty from 'lodash/isEmpty';
import { createElement } from 'react';
import { canalDiccionari } from 'utils/canals';
import Icona from 'components/3cat/ui/Icona';
import styles from './mosaic.module.scss';

const _getTitol = (linkTipus, blocTitol, slug, slug2, pintaTitol) => {
    if (pintaTitol && !__isEmpty(linkTipus) && !__isEmpty(blocTitol)) {
        return (
            <div className={styles.contenidorTitol}>
                <Enllac
                    slug={slug}
                    slugRelacio={slug2}
                    type={linkTipus}
                    className={styles.titol}
                    titol={blocTitol}
                    dataTestId='mosaic-titol'
                ></Enllac>
                <Icona icona='fletxa_simple_dreta' alt='Següent' isBlanc={true} mida={18} className={styles.linktitol} />
            </div>
        );
    }
    if (pintaTitol && !__isEmpty(blocTitol)) {
        return <h2 className={styles.infoTitol}>{blocTitol}</h2>;
    }
};

const _addCanals = (items) => {
    const posCanal33 = __findIndex(items, { name: 'c33' });

    const part1 = items.slice(0, posCanal33);
    const part2 =
        !items || !items[posCanal33].ara_fem
            ? { name: 'c33', ara_fem: canalDiccionari.c33 }
            : { name: 'c33', ara_fem: items[posCanal33].ara_fem };
    const part3 = items.slice(posCanal33, posCanal33.length);

    const part4 = { name: 'bdtv', ara_fem: canalDiccionari.bdtv };

    return [...part1, part2, ...part3, part4];
};

const _getCanals = (items, afegirCanals, mostrarOca) => {
    if (afegirCanals) {
        items = _addCanals();
    }
    if (!mostrarOca) {
        // treure Ocasionals
        let itemsFilter = items && items.length !== 0 && items.filter((element, i) => element.name.startsWith('oca') !== true);
        items = itemsFilter;
    }
    return items;
};

const Mosaic = (props) => {
    const {
        numColumns,
        hGap,
        vGap,
        format,
        routeType,
        classNameMosaic,
        defaultStyle,
        prefetchedData,
        linkTipus,
        blocTitol,
        slug,
        slug2,
        slugRelacio,
        mostraCodiEtic,
        showButtonMoreItems,
        totalItems,
        titol,
        tipologia,
        afegirCanals,
        mostrarOca,
        tipus,
        itemsResponsive,
        mostrarNomPrograma,
        showLogoPrograma,
        numElementsDisplay
    } = props;

    let { items } = props;
    const { itemsPercentatge } = usePercentatgeVist(items);
    const pintaTitol = items?.length > 0;

    const clickTitles = !__isEmpty(items) ? items?.map((item) => item.titol || item.name).join('|') : '';

    const itemsToRender = (localItems) => {
        if (!localItems || localItems.length === 0) return;
        return localItems.map((item, i) => {
            const marcatgeAudienciesClick = {
                clickName: titol || format,
                clickType: 'Tira',
                clickPosition: i + 1,
                clickTitles: clickTitles
            };

            const itemId = item.id ?? i;
            const percentatgeVist = item.percentatge || 0;
            return createElement(formatTypes[format], {
                key: `mosaic-${format}-${itemId}`,
                data: item || {
                    titol: `Carregant item: ${i}`
                },
                navigationId: i,
                style: {
                    ['position']: 'relative',
                    ['marginRight']: '0px',
                    ['width']: '100%'
                },
                field: format,
                ranked: null,
                tipologia,
                routeType,
                slugRelacio,
                prefetchedData,
                marcatgeAudienciesClick: marcatgeAudienciesClick,
                mostraCodiEtic,
                mosaicPosition: { i },
                progres: percentatgeVist,
                tipus: (tipus === 'exclusiusDigitals' && item.tipus) || tipus,
                responsive: itemsResponsive,
                mostrarNomPrograma,
                showLogoPrograma,
                testId: `mosaic-item-${i}`
            });
        });
    };

    const stylesContainerMosaic = clsx({
        [styles.contenidorMosaic]: true,
        [styles.directesHoritzontal]:
            format === 'directe' && (tipus === 'horitzontal' || tipus === 'exclusiusDigitals') && numColumns !== 1,
        [styles.directesHoritzontalLarge]:
            format === 'directe' && (tipus === 'horitzontal' || tipus === 'exclusiusDigitals') && numColumns === 1
    });
    const stylesMosaic = clsx({
        [styles.mosaic]: true,
        [styles[classNameMosaic]]: true,
        [styles[`gap-${hGap}`]]: true,
        [styles[`columns-${numColumns}`]]: !classNameMosaic && !!numColumns,
        [styles[defaultStyle]]: !classNameMosaic && !numColumns
    });

    if (format === 'directe') items = _getCanals(items, afegirCanals, mostrarOca);
    return (
        <div data-testid='mosaic' className={stylesContainerMosaic}>
            {_getTitol(linkTipus, blocTitol, slug, slug2, pintaTitol)}
            <div style={{ gap: `${vGap}px ${hGap}px` }} className={`${stylesMosaic} mosaic`}>
                {itemsToRender(itemsPercentatge || items)}
            </div>
            {showButtonMoreItems && totalItems > numElementsDisplay && (
                <div className={styles.btnMoreItemsContent}>
                    <Boto
                        text='Tots'
                        estil='transparent'
                        mida='s'
                        linkSlug={slug}
                        slug2={slug2}
                        type={linkTipus}
                        dataTestId={`boto-tots`}
                    />
                </div>
            )}
        </div>
    );
};

Mosaic.defaultProps = {
    items: [],
    numColumns: false,
    hgap: 25,
    vGap: 25,
    format: types.KEYFRAME,
    hover: true,
    routeType: 'PROGRAMES',
    defaultStyle: 'default',
    slugRelacio: '',
    mostraCodiEtic: true,
    showButtonMoreItems: false,
    totalItems: null,
    afegirCanals: false,
    tipus: '',
    mostrarOca: true,
    itemsResponsive: true,
    mostrarNomPrograma: false,
    showLogoPrograma: false,
    numElementsDisplay: 8
};

export default Mosaic;
