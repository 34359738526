import { HistoryContext } from 'context/3cat/HistoryProvider';
import { getSlug, getStorageValue, setStorageValue } from '@portal-internet/core';
import { useProducte } from 'hooks/3cat/useProducte';
import { useUser } from 'hooks/useUser';
import { useViewport } from 'hooks/useViewport';
import __isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { setDigitalData, setGlobalEvars } from './audiencies-datalayer';

const MAX_REQUESTS = 2000;

const removeComScoreScript = () => {
    const beginComment = Array.from(document.body.childNodes).find(
        (node) => node.nodeType === Node.COMMENT_NODE && node.nodeValue.trim() === 'Begin comScore Tag'
    );
    const endComment = Array.from(document.body.childNodes).find(
        (node) => node.nodeType === Node.COMMENT_NODE && node.nodeValue.trim() === 'End comScore Tag'
    );

    if (beginComment && endComment) {
        let nextNode = beginComment.nextSibling;
        while (nextNode && nextNode !== endComment) {
            const nodeToRemove = nextNode;
            nextNode = nextNode.nextSibling;
            nodeToRemove.parentNode.removeChild(nodeToRemove);
        }

        beginComment.parentNode.removeChild(beginComment);
        endComment.parentNode.removeChild(endComment);
    }
};

export default function Audiencies(props) {
    const { registarAWebview } = props;
    const { getPreviousPage } = useContext(HistoryContext);
    const { viewport } = useViewport();
    const { isAuthenticated, id, deviceUid } = useUser();
    const router = useRouter();
    const { isWebviewActive } = useProducte();
    const [dataClickOrigin, setDataClickOrigin] = useState({});

    const getDataClick = async () => {
        const data = await getStorageValue('ccma_click');
        if (data && data !== dataClickOrigin) {
            setDataClickOrigin(data);
            setStorageValue('ccma_click', {}, { path: '/', domain: '.3cat.cat', expires: 1 });
        }
    };

    useEffect(() => {
        router.events.on('routeChangeStart', removeComScoreScript);

        return () => {
            router.events.off('routeChangeStart', removeComScoreScript);
        };
    }, [router.events]);

    useEffect(() => {
        getDataClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof window === 'object') {
            if (isWebviewActive && registarAWebview === false) {
                window.DISABLEAUTOHIT = true;
            }
            if (window.s) {
                window.s.pageType = undefined;
            }
            const paginaAnterior = getPreviousPage();

            const tiraOrigen = dataClickOrigin?.name;
            const tiraTitles = dataClickOrigin?.titles;
            const digitalData = setDigitalData({
                ...props,
                isAuthenticated,
                id,
                deviceUid,
                viewport,
                router,
                paginaAnterior,
                tiraOrigen,
                tiraTitles,
                isWebviewActive
            });
            window.digitalData = digitalData;
            setGlobalEvars(digitalData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewport, isAuthenticated, id, deviceUid, router.asPath, dataClickOrigin]);
    useEffect(() => {
        let timerScroll = null;
        let timer = null;
        if (!__isEmpty(router.query.automatic_test) && router.query.automatic_test < MAX_REQUESTS) {
            timerScroll = setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 2500);
            timer = setTimeout(() => {
                const elems = document.querySelectorAll('a[href*="/3cat/"][href*="automatic_test"]:not([href*="/cercador/"]');
                const random = Math.floor(Math.random() * elems.length);
                //console.log('[automatic_test] (' + elems.length + ')', elems[random].getAttribute('href'));
                elems[random]?.click();
            }, 3500);
        }
        return () => {
            clearTimeout(timer);
            clearTimeout(timerScroll);
        };
    }, [router.query.automatic_test]);

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<!-- Comscore Identifier: comscorekw=${getSlug(props?.evar16 || '3cat')?.replace('-', '')} -->`
                }}
            />
        </>
    );
}
