const qaReels = [
    {
        name: 'BlocREDL',
        finalProps: {
            name: 'Reel 12'
        },
        children: [
            {
                name: 'Fila',
                finalProps: {
                    columnes: '12_12_reel',
                    tipus: 'fixed'
                },
                children: [
                    {
                        name: 'Columna',
                        children: [
                            {
                                name: 'Reels',
                                finalProps: {
                                    isLoading: true,
                                    titol: 'Els millors reels de la setmana en format full width'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: 'BlocREDL',
        finalProps: {
            name: 'Reel 8'
        },
        children: [
            {
                name: 'Fila',
                finalProps: {
                    columnes: '8_4',
                    tipus: 'fixed'
                },
                children: [
                    {
                        name: 'Columna',
                        children: [
                            {
                                name: 'Reels',
                                finalProps: {
                                    isLoading: true,
                                    titol: 'Els millors reels de la setmana en format Grid 8'
                                }
                            }
                        ]
                    },
                    {
                        name: 'Columna'
                    }
                ]
            }
        ]
    }
];

export default qaReels;
