function isWebviewPath(asPath) {
    return isWebviewLayout(asPath) || hasWebviewQuery(asPath);
}

function isWebviewLayout(asPath) {
    return asPath.includes('/webview');
}

function hasWebviewQuery(asPath) {
    let querystring = asPath.split('#')[0];
    querystring = querystring.split('?')[1];
    const webviewQuery = new URLSearchParams(querystring).get('webview');
    return webviewQuery === 'yes' || webviewQuery === 'true';
}

function isEmbedPath(asPath) {
    return isEmbedLayout(asPath) || hasEmbedQuery(asPath);
}

function isEmbedLayout(asPath) {
    return asPath.includes('/embed');
}

function hasEmbedQuery(asPath) {
    let querystring = asPath.split('#')[0];
    querystring = querystring.split('?')[1];
    const embedQuery = new URLSearchParams(querystring).get('embed');
    return embedQuery === 'yes' || embedQuery === 'true';
}

function isWebviewPlayerPath(asPath) {
    return asPath.includes('/embed/appmobil/video') && hasWebviewQuery(asPath);
}

function isNoticiesPath(asPath) {
    return asPath.includes('/3catinfo/');
}

export { isEmbedPath, isNoticiesPath, isWebviewPath, isWebviewPlayerPath };
