import { useContext, useEffect } from 'react';
import { ServicesContext } from 'context/ServicesProvider';
import { servicesStatus as fallbackServicesStatus, servicesGrants as fallbackServicesGrants } from './fallbackServicesInfo';

export default function ServicesListener(props) {
    const { dataFetchProps } = props;
    const servicesStatus = dataFetchProps?.servicesStatus?.data?.serveis || fallbackServicesStatus.serveis;
    const servicesGrants = dataFetchProps?.servicesGrants?.data?.serveis || fallbackServicesGrants.serveis;
    const { setServicesStatusInfo, setServicesGrantsInfo } = useContext(ServicesContext);

    useEffect(() => {
        setServicesStatusInfo(servicesStatus);
        setServicesGrantsInfo(servicesGrants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servicesStatus, servicesGrants]);

    return <></>;
}
