import dynamic from 'next/dynamic';

export const formatTypes = {
    keyframe: dynamic(() => import(`./Keyframe`)),
    noticia: dynamic(() => import(`./Noticia`)),
    poster: dynamic(() => import(`./Poster`)),
    directe: dynamic(() => import(`./Directe`)),
    quadrat: dynamic(() => import(`./Quadrat`))
};

export const types = {
    KEYFRAME: 'keyframe',
    NOTICIA: 'noticia',
    POSTER: 'poster',
    DIRECTE: 'directe',
    QUADRAT: 'quadrat'
};
