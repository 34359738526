import { getHora } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Icona from 'components/3cat/ui/Icona';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import styles from './directe.module.scss';

const _getCanal = (canal) => {
    switch (canal) {
        case 'CAD_ES3':
            return 'esport3';
        case 'CAD_OCA1':
        case 'CAD_OCA2':
        case 'CAD_OCA3':
        case 'CAD_OCA4':
            return 'oca1';
        default:
            return canal;
    }
};
const _updateImatges = (e) => {
    const imatges = e?.ara_fem?.imatges;
    const destacat_imatge = e?.ara_fem?.destacat_imatge;

    if (!__isEmpty(imatges) && destacat_imatge) {
        return [...imatges, { text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else if (destacat_imatge) {
        return [{ text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else {
        return imatges;
    }
};
const _getInfoVideo = (e) => {
    const imatges = _updateImatges(e);
    return {
        titol: e.ara_fem?.titol_programa,
        capitol: e.ara_fem?.titol_capitol || e.ara_fem?.capitols?.[0].desc,
        link: e.ara_fem?.codi_canal,
        canal: e.ara_fem?.codi_canal,
        logo: e.ara_fem?.logo,
        imatge: imatges,
        start_time: e.ara_fem?.start_time,
        end_time: e.ara_fem?.end_time,
        codi_etic: e.ara_fem?.codis_etics,
        drets_cat: e.ara_fem?.drets_cat !== 'no',
        tipusDirecte: e.ara_fem?.tipus
    };
};

const _getInfoDespres = (e) => {
    return e
        ? {
              df_hora: getHora(e.start_time),
              df_titol: e.titol_programa,
              df_entradeta: e.titol_capitol
          }
        : {};
};
export default function Directe(props) {
    const { data, estil, marcatgeAudienciesClick } = props;
    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');
    const finalInfo = _getInfoVideo(data);
    const { codi_etic, logo, imatge, start_time, end_time, itemCapitol, tipusDirecte } = finalInfo;
    let { canal, itemTitol } = finalInfo;
    let disponible = data.ara_fem?.drets_cat !== 'no';

    const { isWebviewActive } = useProducte();

    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.directeHover]: true }));
            }, 200);
        }
    }, [isHover]);

    if (__isEmpty(itemTitol) && __isEmpty(imatge) && __isEmpty(finalInfo.link)) {
        if (data.name == 'c33') {
            canal = 'c33';
            itemTitol = 'El canal emet de 21.30 a 05.59';
            disponible = false;
        } else {
            return <></>;
        }
    }
    const despresInfo = _getInfoDespres(data.despres_fem);

    const route = {
        id: canal === 'c33' ? '33' : canal,
        tipologia: 'DIRECTE',
        tipusDirecte
    };

    const stylesItemMosaic = clsx({
        [styles.itemMosaic]: true,
        [styles.inactiu]: !disponible,
        [styles[estil]]: true,
        ['focusable']: true
    });

    const hoverHandler = (e) => {
        setIsHover(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    return (
        <div className={`${stylesItemMosaic} ${divHover}`} onMouseEnter={hoverHandler} onMouseLeave={outHandler}>
            <div className={styles.wrapperItemMosaic}>
                <Enllac route={route} disponible={disponible} marcatgeAudienciesClick={marcatgeAudienciesClick}>
                    <div className={styles.imatge}>
                        <Imatge
                            imatges={imatge}
                            tipus='web_mosaic'
                            alt={itemTitol}
                            aria-hidden='true'
                            layout='fill'
                            objectFit='cover'
                            loading='lazy'
                            crop='555x312'
                        />
                        <div className={styles.bottomgradient}>
                            <LogoDirecte className={`${canal} ${styles.logocanal}`} canal={_getCanal(canal)} logo={logo} />
                        </div>
                        {disponible && (
                            <div className={styles.icona_play}>
                                <Icona icona='play' className={styles.play} alt='reproduir' isBlanc={true} mida={18} />
                            </div>
                        )}
                    </div>
                    {!disponible && <span className={styles.msginactiu}>No disponible</span>}
                </Enllac>

                <Enllac
                    route={isWebviewActive ? { ...route, format: 'KEYFRAME' } : route}
                    disponible={disponible}
                    className={styles.info}
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                >
                    <div className={`${styles.descripcio} ${isWebviewActive && styles.descripcioWebview}`}>
                        <div>
                            {itemTitol && <h2 className={styles.titol}>{itemTitol}</h2>}
                            {itemCapitol && <p className={styles.capitol}>{itemCapitol}</p>}
                        </div>
                        {isWebviewActive && disponible && (
                            <Icona icona='info_contorn' className={styles.iconaInfo} alt='Info' isBlanc={true} mida={22} />
                        )}
                    </div>
                    <div className={`${styles.codiDurada} ${isWebviewActive && styles.isWebviewActive}`}>
                        {codi_etic && (
                            <CodiEspectador className={styles.codiespectador} codi={codi_etic} alt={codi_etic} mida={20} />
                        )}
                    </div>
                    <ProgressBar className={styles.progres} start_time={start_time} end_time={end_time} />
                    <div className={styles.entradeta}>
                        {despresInfo.df_titol && (
                            <p className={styles.despres_fem}>
                                <Icona icona='continuacio' alt='icona a continuació' mida={15} className={styles.ico_next} />A
                                continuació: {despresInfo.df_hora}
                                <span className={styles.despres_info}>
                                    <strong> {despresInfo.df_titol}</strong>
                                    {despresInfo.df_entradeta && <>. {despresInfo.df_entradeta}</>}
                                </span>
                            </p>
                        )}
                    </div>
                </Enllac>
            </div>
        </div>
    );
}
