// The current application coordinates were pre-registered in a B2C tenant.

let jitConfigAzureHost;

if (typeof window !== 'undefined') {
    jitConfigAzureHost = window.jitConfig.azureHost;
}

const apiConfig = {
    b2cScopes: [jitConfigAzureHost + '/tasks-api/tasks.read']
};

export { apiConfig };
