import { useEffect, useState, useRef, useCallback, memo } from 'react';
import clsx from 'clsx';

import { computSimpleHashFromData } from '../utils/utils';

import styles from './redlnotrenderableinfo.module.scss';

const FADE_OUT_DURATION = 300; // Match this to the CSS transition duration

const processArray = (array) => {
    let minIndex = Infinity;
    let maxIndex = -Infinity;
    const uniqueValues = new Set();

    array.forEach((value, index) => {
        if (!value) return;

        minIndex = Math.min(minIndex, index);
        maxIndex = Math.max(maxIndex, index);
        uniqueValues.add(value);
    });

    return {
        minIndex: minIndex === Infinity ? null : minIndex,
        maxIndex: maxIndex === -Infinity ? null : maxIndex,
        uniqueValues: Array.from(uniqueValues),
    };
};

const RedlNotRenderableInfo = memo(({ pageNotRenderableInfo }) => {
    const [messages, setMessages] = useState({});
    const [isVisible, setIsVisible] = useState(true);
    const [isFading, setIsFading] = useState(false);
    const prevHashRef = useRef(null);

    useEffect(() => {
        if (!pageNotRenderableInfo) return;

        const currentHash = computSimpleHashFromData(pageNotRenderableInfo);
        if (currentHash === prevHashRef.current) return;

        const updatedMessages = Object.entries(pageNotRenderableInfo).reduce((acc, [dataId, itemsArray]) => {
            const { minIndex, maxIndex, uniqueValues } = processArray(itemsArray);

            if (minIndex !== null && maxIndex !== null) {
                const itemsMessage =
                    minIndex === maxIndex ? `l'ítem ${minIndex} necessari` : `els ítems ${minIndex}-${maxIndex} necessaris`;

                const modulesMessage =
                    uniqueValues.length === 1 ? `el mòdul "${uniqueValues[0]}"` : `els mòduls "${uniqueValues.join('", "')}"`;

                acc[
                    dataId
                ] = `La font de dades "${dataId}" actualment no conté ${itemsMessage} per a omplir ${modulesMessage} del final de la pàgina actual.`;
            }

            return acc;
        }, {});

        setMessages(updatedMessages);
        setIsVisible(Object.keys(updatedMessages).length > 0);

        prevHashRef.current = currentHash;
    }, [pageNotRenderableInfo]);

    const renderMessages = useCallback(
        () =>
            Object.entries(messages).map(([dataId, message]) => (
                <p key={dataId} className={styles.redlNotRenderableInfo__text}>
                    {message}
                </p>
            )),
        [messages]
    );

    const handleClose = useCallback(() => {
        setIsFading(true);
        setTimeout(() => {
            /** Hide the component after the fade-out duration */
            setIsVisible(false);
        }, FADE_OUT_DURATION);
    }, []);

    const containerClass = clsx(styles.redlNotRenderableInfo, {
        [styles.fading]: isFading,
        [styles.notVisible]: !messages || !isVisible
    });

    return (
        <div className={containerClass} data-testid='container-redlNotRenderableInfo'>
            <button className={styles.closeButton} onClick={handleClose} data-testid='btn-close-redlNotRenderableInfo'>
                X
            </button>
            {renderMessages()}
        </div>
    );
});

RedlNotRenderableInfo.displayName = 'RedlNotRenderableInfo';
export default RedlNotRenderableInfo;
