import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getDynamicComponents } from 'components';
import AvisCookies from 'components/3catinfo/AvisCookies';
import Modal from 'components/3catinfo/Modal';
import ErrorBoundary from 'components/3catinfo/ErrorBoundary';
import Loading from 'components/3catinfo/Loading';
// import Onboarding from 'components/3catinfo/Onboarding';
import ServicesListener from 'components/ServicesListener';
import ContentUserProvider from 'context/3catinfo/ContentUserProvider';
import GlobalVarsProvider from 'context/GlobalVarsProvider';
import HistoryProvider from 'context/3catinfo/HistoryProvider';
import LoadingProvider from 'context/3catinfo/LoadingProvider';
import ModalProvider from 'context/3catinfo/ModalProvider';
import PublicitatProvider from 'context/PublicitatProvider';
import RedlMetadataProvider from 'context/REDL/RedlMetadataProvider';
import ServicesProvider from 'context/ServicesProvider';
import UserDelivertyProvider from 'context/UserDelivertyProvider';
import UserProvider from 'context/UserProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { msalConfig } from 'utils/login/authConfig';
import styles from '../../../web/styles/noticies/globals.module.scss';
import RedlProvider from '../../context/REDL/RedlProvider';

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();
const DynamicComponents = getDynamicComponents('3catinfo');

const App3catinfo = ({ Component, pageProps }) => {
    return (
        <ErrorBoundary>
            <GlobalVarsProvider>
                <QueryClientProvider client={queryClient}>
                    <HistoryProvider>
                        <MsalProvider instance={msalInstance}>
                            <LoadingProvider>
                                <RedlMetadataProvider>
                                    <RedlProvider>
                                        <ServicesProvider>
                                            <UserProvider>
                                                <UserDelivertyProvider>
                                                    <ContentUserProvider>
                                                            <PublicitatProvider>
                                                                <ModalProvider>
                                                                    <AvisCookies {...pageProps} />
                                                                    <div id='__mainContent' className={styles.noticies}>
                                                                        <ServicesListener {...pageProps} />
                                                                        <Component
                                                                            {...pageProps}
                                                                            dynamicComponents={DynamicComponents}
                                                                        />
                                                                        <Loading />
                                                                        <Modal {...pageProps} />
                                                                    </div>
                                                                    <div id='app-modal' className={styles.noticies} />

                                                                    {/* <Onboarding /> */}
                                                                    {process.env.isDev && (
                                                                        <ReactQueryDevtools initialIsOpen={false} />
                                                                    )}
                                                                </ModalProvider>
                                                            </PublicitatProvider>
                                                    </ContentUserProvider>
                                                </UserDelivertyProvider>
                                            </UserProvider>
                                        </ServicesProvider>
                                    </RedlProvider>
                                </RedlMetadataProvider>
                            </LoadingProvider>
                        </MsalProvider>
                    </HistoryProvider>
                </QueryClientProvider>
            </GlobalVarsProvider>
        </ErrorBoundary>
    );
};

export default App3catinfo;
