import clsx from 'clsx';
import Structure from 'components/3cat/Structure';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import styles from './fila.module.scss';

export default function Fila(props) {
    const { tipus, estil, columnes } = props;
    const rowClass = 'row' + tipus;
    const colClass = 'col' + columnes;
    const { isWebviewActive } = useProducte();

    const stylesRow = clsx({
        [styles.row]: true,
        [styles[rowClass]]: !__isEmpty(tipus),
        [styles[estil]]: !__isEmpty(estil),
        [styles.webview]: isWebviewActive
    });

    const stylesCol = clsx({
        [styles[colClass]]: !__isEmpty(columnes)
    });

    return (
        <div className={`${stylesRow} ${stylesCol}`}>
            <Structure {...props} />
        </div>
    );
}
