import { useEffect, createContext, useState } from 'react';
import { useRouter } from 'next/router';
import { removeUrlHash, setMarcatgeMrec, setMarcatgeMrecWebview } from '@portal-internet/core';
import { useGlobalVars } from 'hooks/useGlobalVars';
import { useProducte } from 'hooks/3cat/useProducte';

export const HistoryContext = createContext();

const HistoryProvider = (props) => {
    const { children } = props;
    const { getGlobalVar, resetGlobalVar } = useGlobalVars();
    const router = useRouter();
    const { isWebviewActive } = useProducte();
    const [historyPages, setHistoryPages] = useState([]);

    useEffect(() => {
        setHistoryPages([...historyPages, removeUrlHash(router.asPath, 'mrec')]);
        if (isWebviewActive) {
            if (historyPages[historyPages.length - 1] !== router.asPath) setMarcatgeMrecWebview(router);
        } else {
            setMarcatgeMrec(getGlobalVar, resetGlobalVar);
        }
    }, [router]);

    const getPreviousPage = () => {
        if (historyPages.length === 0) return false;
        return historyPages[historyPages.length - 1];
    };
    return (
        <HistoryContext.Provider
            value={{
                historyPages,
                getPreviousPage
            }}
            children={children}
        />
    );
};

export default HistoryProvider;
