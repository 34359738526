import { getStorageValue } from '@portal-internet/core';
import clsx from 'clsx';
import Icona from 'components/3cat/ui/Icona/Icona';
import { useLaMevaLlista } from 'hooks/3cat/useLaMevaLlista';
import { useProducte } from 'hooks/3cat/useProducte';
import { useUser } from 'hooks/useUser';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import { useEffect, useRef, useState } from 'react';
import styles from './afegeixalallista.module.scss';

function AfegeixALaLlista(props) {
    const { tooltipPosition, className, id, tipologia, positionCarrussel } = props;
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [toolTipText, setTooltipText] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const [isBackFromLogin, setIsBackFromLogin] = useState(false);
    const [positionInFetch, setPositionInFetch] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isALaMevaLlista, eliminaALaMevaLlista, afegeixALaMevaLlista, loaded } = useLaMevaLlista();
    const isItemAlaMevaLlista = isALaMevaLlista(id, tipologia);
    const { isAuthenticated, accessToken, state: userState, signUp } = useUser();

    const timeoutRef = useRef();
    const ref = useRef();

    const { isWebviewActive } = useProducte();
    const stylesAfegeix = clsx({
        [styles.boto]: true,
        [styles.hover]: isTooltipVisible,
        [styles.buttonShow]: isBackFromLogin,
        [className]: !__isEmpty(className)
    });
    const stylesTooltip = clsx({
        [styles.tooltip]: true,
        [styles.tooltipfloat]: !isTouched,
        [styles.tooltiptouch]: isTouched,
        [styles[tooltipPosition]]: true
    });

    useEffect(() => {
        return () => clearTimeout(timeoutRef?.current);
    }, []);

    useEffect(() => {
        let timeout = null;
        if (!isWebviewActive) {
            timeout = setTimeout(async () => {
                setPositionInFetch(await getStorageValue('web-fetch-page'));
            }, 100);
        }
        return () => {
            if (!isWebviewActive && timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isWebviewActive]);

    useEffect(() => {
        if (
            !isAuthenticated ||
            !accessToken ||
            !loaded ||
            !userState ||
            userState.namespace !== '[web]items-usuari-afegeix-a-la-llista' ||
            userState.addItemToLlistaUsuari !== id
        )
            return null;

        if (!isItemAlaMevaLlista) onActionToList();

        const timeoutScroll = setTimeout(() => {
            if (__isUndefined(userState?.positionCarrussel)) ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 300);

        setIsBackFromLogin(true);
        const timeoutTooltip = setTimeout(() => {
            setIsBackFromLogin(false);
        }, 4000);

        return () => {
            clearTimeout(timeoutScroll);
            clearTimeout(timeoutTooltip);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, loaded]);

    const waitAndHideTooltip = () => {
        clearTimeout(timeoutRef?.current);
        timeoutRef.current = setTimeout(() => {
            setIsTooltipVisible(false);
        }, 3000);
    };

    const onActionToList = async () => {
        if (!isAuthenticated) {
            signUp({
                namespace: '[web]items-usuari-afegeix-a-la-llista',
                addItemToLlistaUsuari: id,
                currentPage: positionInFetch,
                positionCarrussel: positionCarrussel
            });
            return;
        }
        setIsLoading(true);
        const statusIsOk = isItemAlaMevaLlista
            ? await eliminaALaMevaLlista(id, tipologia)
            : await afegeixALaMevaLlista(id, tipologia);
        setIsLoading(false);

        if (statusIsOk) {
            setTooltipText(isItemAlaMevaLlista ? 'Eliminat de "La meva llista"' : 'Afegit a "La meva llista"');
        } else {
            setTooltipText(
                isItemAlaMevaLlista ? `No s'ha pogut eliminar de "La meva llista"` : `No s'ha pogut afegir a "La meva llista"`
            );
        }

        setIsTooltipVisible(true);
        waitAndHideTooltip();
    };

    const onClickList = async (e) => {
        if (!isLoading) onActionToList();
    };

    const iconButton = () => {
        if (isLoading) {
            return <div className={styles.loadingIcon} />;
        }
        const estilIcona = isItemAlaMevaLlista ? 'checked_contorn_cercle' : 'mes_contorn_cercle';
        return <Icona inline={true} icona={estilIcona} color='blanc' alt={altIcona} />;
    };

    const altIcona = isItemAlaMevaLlista ? 'Elimina de "La meva llista"' : 'Afegeix a "La meva llista"';
    return (
        <button
            ref={ref}
            className={stylesAfegeix}
            aria-label={altIcona}
            onTouchStart={() => {
                setIsTouched(true);
                onClickList;
            }}
            onClick={onClickList}
            onMouseEnter={() => {
                setTooltipText(isItemAlaMevaLlista ? 'Elimina de "La meva llista"' : 'Afegeix a "La meva llista"');
                setIsTooltipVisible(true);
            }}
            onMouseLeave={() => {
                clearTimeout(timeoutRef?.current);
                setIsTooltipVisible(false);
            }}
            data-testid='afegeix-a-la-llista'
        >
            {iconButton()}
            {isTooltipVisible && (
                <div className={stylesTooltip} aria-hidden='true'>
                    {toolTipText}
                </div>
            )}
        </button>
    );
}

AfegeixALaLlista.defaultProps = {
    tooltipPosition: 'left'
};

export default AfegeixALaLlista;
