import { genericPage, getServerSidePropsHelper } from 'components/3cat/Page';
import { useRouter } from 'next/router';
// /
const path = ['web', process.env.FOLDER_LAYOUTS];

const Pagina = (props) => {
    const router = useRouter();
    return genericPage(props, router, path);
};

export async function getServerSideProps(context) {
    return getServerSidePropsHelper(context, { slugs: path });
}

export default Pagina;
