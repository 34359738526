import { Structure } from '@portal-internet/components';
import { getDynamicComponents } from 'components';
import qaOberturaDiaria from './skeletonPages/qaOberturaDiaria';
import qaReels from './skeletonPages/qaReels';
import qaOberturaVisual from './skeletonPages/qaOberturaVisual';
import qaOberturaVisualInvertit from './skeletonPages/qaOberturaVisualInvertit';
import qaOberturaDefault from './skeletonPages/qaOberturaDefault';
import qaOberturaDefaultInvertit from './skeletonPages/qaOberturaDefaultInvertit';
import qaOberturaCritica from './skeletonPages/qaOberturaCritica';
import qa3333 from './skeletonPages/qa3333';
import styles from '../loading.module.scss';

const getStructure = (props) => {
    const { type, slug } = props;

    switch (type) {
        case 'web_actualitat_qa_blocdecontinguts':
            switch (slug) {
                case 'reels':
                    return qaReels;
                case '3333':
                    return qa3333;
                case 'critica':
                    return qaOberturaCritica;
                case 'visual':
                    return qaOberturaVisual;
                case 'visual_invertit':
                    return qaOberturaVisualInvertit;
                case 'default':
                    return qaOberturaDefault;
                case 'default_invertit':
                    return qaOberturaDefaultInvertit;
                case 'diaria':
                    return qaOberturaDiaria;
                default:
                    return false;
            }

        default:
            return false;
    }
};

const SkeletonLoadingPages = (props) => {
    const structure = getStructure(props);
    const DynamicComponents = getDynamicComponents('3catinfo');
    if (!structure) return <></>;

    return (
        <div className={styles.loadingSkeleton} data-content='skeleton-loading-page'>
            <div className={styles.skeletonContent}>
                <Structure
                    {...{
                        structure,
                        dynamicComponents: DynamicComponents,
                        routerQuery: {}
                    }}
                />
            </div>
        </div>
    );
};

export default SkeletonLoadingPages;
