export const servicesStatus = {
    serveis: [
        {
            name: 'fallback',
            status: 'ok'
        }
    ]
};

export const servicesGrants = {
    serveis: [
        {
            id: 'fallback',
            name: 'Servei general no especificat',
            userAgentGrants: {
                allowList: {
                    tot: '.*'
                },
                blockList: {}
            },
            pathnameGrants: {
                allowList: {
                    tot: '.*'
                },
                blockList: {}
            }
        }
    ]
};
