import { useProducte } from 'hooks/3cat/useProducte';
import Directe from './Directe';
import DirecteHoritzontal from './Directe.horitzontal';
import DirecteHoritzontalWebview from './Directe.horitzontal.webview';

export default function Component(props) {
    const { tipus } = props;
    const isFastChannel = tipus === 'fastChannel';
    const { isWebviewActive } = useProducte();
    if (tipus === 'horitzontal' || tipus === 'fastChannel') {
        return isWebviewActive ? (
            <DirecteHoritzontalWebview {...props} isFastChannel={isFastChannel} />
        ) : (
            <DirecteHoritzontal {...props} isFastChannel={isFastChannel} />
        );
    }
    return <Directe {...props} />;
}
