import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import styles from './progressbar.module.scss';

function ProgressBar(props) {
    let { progress, start_time, end_time, className } = props;
    const styleProgress = clsx({
        [styles.progress]: true,
        [className]: !__isEmpty(className)
    });
    const _getProgress = (start_time, end_time) => {
        const current_time = new Date().getTime();
        start_time = new Date(start_time).getTime();
        end_time = new Date(end_time).getTime();
        const duration_time = end_time - start_time;
        const elapsed_time = current_time - start_time;
        return Math.min(Math.max(parseInt((elapsed_time / duration_time) * 100), 0), 100);
    };
    if (!__isEmpty(start_time) && !__isEmpty(end_time)) {
        progress = _getProgress(start_time, end_time);
    }
    if (__isEmpty(start_time) && __isEmpty(end_time) && (progress === undefined || progress === 0)) {
        return false;
    }
    return (
        <div className={styleProgress}>
            <span className={styles.bar} style={{ width: progress + '%' }}></span>
        </div>
    );
}

export default ProgressBar;
