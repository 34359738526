import { getItems } from '@portal-internet/core';
import { getData } from '@portal-internet/bff';
import Carrussel from 'components/3cat/Carrussel';
import ErrorBoundary from 'components/3cat/ErrorBoundary';
import { useQuery } from 'react-query';

export default function CarrusselWrapper(props) {
    const { prefetchedData, id, urlDadesByProducte, field } = props;
    let { items } = props;

    const initialData = prefetchedData?.[id] ? prefetchedData?.[id] : null;
    const { data } = useQuery([field, { url: urlDadesByProducte }], getData, {
        initialData: initialData,
        staleTime: Infinity,
        refetchOnMount: !initialData ? 'always' : false
    });
    items = items || getItems(data);
    return items ? (
        <ErrorBoundary hideError={true}>
            <Carrussel items={items} />
        </ErrorBoundary>
    ) : null;
}
