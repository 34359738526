import Metadades from './Metadades';
import MetadadesWebview from './Metadades.webview';

export default function Component(props) {
  const { isWebviewActive } = props;
  if (isWebviewActive) {
    return <MetadadesWebview {...props} />;
  }
  return <Metadades {...props} />;
}
