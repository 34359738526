import { getDurada, removeHtmlTags } from '@portal-internet/core';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import __isEmpty from 'lodash/isEmpty';
import styles from './poster.module.scss';

const Info = (props) => {
    const { data, route, mostraCodiEtic, barraprogres, progres } = props;
    const { codi_etic, titol, durada, codi_alt } = data;

    const _getEntradeta = () => {
        const { entradeta_promo, entradeta } = data;
        return removeHtmlTags(entradeta_promo || entradeta);
    };

    const _showEntradeta = () => {
        const entradeta = _getEntradeta();
        return (
            <>
                {!__isEmpty(titol) && <h2 className={styles.titol}>{titol}</h2>}
                {!__isEmpty(entradeta) && (
                    <div className={styles.entradeta} dangerouslySetInnerHTML={{ __html: entradeta }}></div>
                )}
            </>
        );
    };

    const _showCodiEspectador = (mostraCodiEtic) => {
        if (__isEmpty(codi_etic) || !mostraCodiEtic) {
            return null;
        }
        return <CodiEspectador className={styles.codiespectador} codi={codi_etic} alt={codi_alt} mida={20} />;
    };

    const _showDurada = () =>
        durada && (
            <>
                <Icona
                    className={styles.ico_clock}
                    mida={24}
                    icona='rellotge'
                    isBlanc={true}
                    alt='Icona rellotge'
                    ariaHidden={true}
                />{' '}
                <span className={styles.durada}>
                    <span className='sr-only'>Durada: </span>
                    {getDurada(durada)}
                </span>
            </>
        );

    const _showProgress = () => barraprogres && <ProgressBar className={styles.progres} progress={progres} />;
    return (
        <>
            <Enllac route={route} className={styles.textWrapper}>
                {_showEntradeta()}
                <div className={styles.extrainfo}>
                    <div>
                        {_showCodiEspectador(mostraCodiEtic)}
                        {_showDurada()}
                    </div>
                    {_showProgress()}
                </div>
            </Enllac>
        </>
    );
};
export default Info;
