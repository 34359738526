import { createContext, useState } from 'react';

export const GlobalVarsContext = createContext();

const GlobalVarsProvider = (props) => {
    const { children } = props;
    const [globalVars, setGlobalVars] = useState({});

    const getGlobalVar = (key) => {
        return globalVars[key];
    };
    const setGlobalVar = (key, value) => {
        const globalVar = {};
        globalVar[key] = value;
        setGlobalVars({ ...globalVars, ...globalVar });
    };

    const resetGlobalVar = (key) => {
        delete globalVars[key];
        setGlobalVars({ ...globalVars });
    };

    return (
        <GlobalVarsContext.Provider
            value={{
                getGlobalVar,
                setGlobalVar,
                resetGlobalVar
            }}
            children={children}
        />
    );
};

export default GlobalVarsProvider;
