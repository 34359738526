import { useUser } from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { getPercentatgeVist, getPercentatgeVistAudio } from '@portal-internet/core';
import __isEmpty from 'lodash/isEmpty';

export const usePercentatgeVist = (items) => {
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated, accessToken } = useUser();
    const [itemsPercentatge, setItemsPercentatge] = useState();

    const fetchDataPercentatgeVist = async () => {
        setIsLoading(true);
        try {
            for (const item of items) {
                if (item['durada'] !== undefined) {
                    if (item['tipologia'] === 'WCR_AUDIO_MM') {
                        item['percentatge'] = await getPercentatgeVistAudio(item['id'], item['durada']);
                    } else {
                        item['percentatge'] = await getPercentatgeVist(item['id'], item['durada']);
                    }
                } else {
                    item['percentatge'] = 0;
                }
            }
            setIsLoading(false);
            setItemsPercentatge(items);
        } catch (error) {
            console.log('Error fetching data getVistPlayList:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated && accessToken && !__isEmpty(items)) {
            fetchDataPercentatgeVist();
        }
    }, [isAuthenticated, accessToken, items]);

    return {
        isLoading,
        itemsPercentatge
    };
};
