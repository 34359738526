const qaOberturaCritica = [
    {
        name: 'BlocREDL',
        finalProps: {
            name: 'Obertura diaria'
        },
        children: [
            {
                name: 'Fila',
                finalProps: {
                    columnes: '12',
                    tipus: 'fixed'
                },

                children: [
                    {
                        name: 'Destacat',
                        finalProps: {
                            isLoading: true,
                            tipologia: 'NOT_NOTICIA'
                        }
                    }
                ]
            }
        ]
    }
];

export default qaOberturaCritica;
