function getNomCanal(canal) {
    switch (canal) {
        case 'e3':
        case 'esport3':
            return 'Esport3';
        case 'tv3':
            return 'TV3';
        case 'tv3cat':
            return 'Tv3Cat';
        case '324':
            return '324';
        case 'c33':
        case '33d':
        case 'cs3/33d':
        case '33':
            return '33';
        case 's3':
        case 's3s3':
        case 's3x3':
        case 'sx3':
            return 'SX3';
        case 'cr':
            return 'Catalunya Ràdio';
        case 'ci':
            return 'Catalunya Informació';
        case 'icat':
        case 'ic':
            return 'iCat';
        case 'cm':
            return 'Catalunya Música';
    }

    return 'exclusiu digital';
};

export { getNomCanal };

