import { getDataInfoVideo } from '@portal-internet/core';
import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import Icona from 'components/3cat/ui/Icona/Icona';
import { useProducte } from 'hooks/3cat/useProducte';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import Info from './Info';
import styles from './poster.module.scss';

const _showIconaAudio = (tipologia, mida) => {
    const styleIcona = clsx({
        [styles.overIcon]: true,
        [styles.iconaGran]: mida === 'web_postergran'
    });

    if (tipologia !== 'WCR_PROGRAMA') return <></>;

    return (
        <span className={styleIcona}>
            <Icona icona='auriculars_ple' className={styles.innerOverIcon} alt='icona auriculars' />
        </span>
    );
};

const _getRouteWebview = (route, isWebviewActive) => {
    return isWebviewActive ? { ...route, format: 'POSTER' } : route;
};

const _getIdAfegeixLlista = (finalInfo) => {
    const { link, tipologia, idRelacionat } = finalInfo;
    return tipologia === 'DTY_DESTACAT' ? idRelacionat : link;
};

const _getTipologiaAfegeixLlista = (finalInfo) => {
    const { tipologia, tipologiaRelacionada } = finalInfo;
    return tipologia === 'DTY_DESTACAT' ? tipologiaRelacionada : tipologia;
};

const Poster = (props) => {
    const {
        data,
        mida,
        hover,
        style,
        ranked,
        rankedMobile,
        marcatgeAudienciesClick,
        mosaicPosition,
        nomPrograma,
        showAfegeixALaLlista,
        mostraCodiEtic,
        routeType,
        slugRelacio,
        barraprogres,
        progres,
        testId
    } = props;

    const { isWebviewActive } = useProducte();

    const finalInfo = getDataInfoVideo(data);
    const { nombonic, link, tipologia, canals, domini, titol, tipologianom, url, permatitle } = finalInfo;

    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');

    const route = {
        type: routeType,
        id: link,
        tipologia: tipologia,
        canals: canals,
        domini: domini || 'TVC',
        slug: nombonic || permatitle || titol,
        slugPrograma: nomPrograma || finalInfo.nomprograma,
        tipologiaRelacionada: tipologianom,
        href: tipologia !== 'WCR_PROGRAMA' ? url : null,
        slugRelacio: slugRelacio
    };

    const styleMosaic = clsx({
        [styles.mosaic]: !!mosaicPosition,
        [styles.hasHover]: !!hover,
        [styles.poster]: true,
        [styles.ranking]: !__isEmpty(ranked),
        [styles.rankingMobile]: !__isEmpty(rankedMobile)
    });

    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.wrapperHover]: true }));
            }, 200);
        }
    }, [isHover]);

    const hoverHandler = (e) => {
        setIsHover(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    return data ? (
        <div
            key={data.id}
            style={{ ...style }}
            className={`${styleMosaic}`}
            onMouseEnter={hoverHandler}
            onMouseLeave={outHandler}
            data-testid={testId}
        >
            <div className={`${styles.wrapper} ${divHover}`}>
                <Info
                    data={finalInfo}
                    route={_getRouteWebview(route, isWebviewActive)}
                    mostraCodiEtic={mostraCodiEtic}
                    barraprogres={barraprogres}
                    progres={progres}
                />

                <Enllac
                    dataTestId={`poster-enllac-img-${data.id}`}
                    route={_getRouteWebview(route, isWebviewActive)}
                    className={styles.imatgeWrapper}
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                >
                    <Imatge
                        className={styles.imatge}
                        imatges={data.imatges}
                        tipus={mida}
                        alt={data.titol}
                        layout='fill'
                        objectFit='cover'
                    />
                </Enllac>

                {_showIconaAudio(tipologia, mida)}

                {showAfegeixALaLlista && tipologia !== 'DTY_DESTACAT' && (
                    <AfegeixALaLlista
                        className={styles.afegeix}
                        id={_getIdAfegeixLlista(finalInfo)}
                        tipologia={_getTipologiaAfegeixLlista(finalInfo)}
                    />
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};

Poster.defaultProps = {
    showAfegeixALaLlista: true,
    mida: 'web_postergran',
    mostraCodiEtic: true,
    barraprogres: true,
    routeType: '',
    slugRelacio: ''
};

export default Poster;
