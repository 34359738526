import { createContext, useState, useEffect } from 'react';

export const PublicitatContext = createContext();



const PublicitatProvider = (props) => {
    const { children } = props;
    const [setupPubliFinished, setSetupPubliFinished] = useState(false);

    const _setupSmart = async () => {
        let sasSetup = {
            networkid: 1029, 
            domain: "https://www3.smartadserver.com", 
            async: true, 
            renderMode: 2
        }
    
        /* Mirem si tenim la cookie informada per les apps per agafar el uid, appName i el built*/
        if(window.CCMAStorage){
            const cookieSmartPlain = await window.CCMAStorage.get('CCMA_Smart_Apps');
            try {
                if(cookieSmartPlain) {
                    const cookieSmart = JSON.parse(cookieSmartPlain);
                    sasSetup.uid = cookieSmart.uid;
                    sasSetup.appName = cookieSmart.appName;
                    sasSetup.bundleId = cookieSmart.bundleId;
                }
            } catch(e) {
                console.log("publicitat - cookieSmartPlain no te el format esperat");
            }
        }
        window.sas?.cmd?.push(function() {
            window.sas.setup(sasSetup);
            setSetupPubliFinished(true);
        });
    }

    useEffect(() => {
        _setupSmart();
    }, []);

    return <PublicitatContext.Provider value={{ setupPubliFinished }} children={children} />;
};


export default PublicitatProvider;
