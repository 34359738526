/* eslint-disable default-case */
import { dataResources, getUrlDadesByProducte } from '@portal-internet/core';
import Mosaic from 'components/3cat/Mosaic';
import { useProducte } from 'hooks/3cat/useProducte';
import React, { createElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Debug, { isDebugActive } from '../../Debug';
import { components } from './components';
import styles from './virtualscroll.module.scss';

const getUrlLayout = (layoutPath) => {
    return `${dataResources.layoutURLHost}/web${layoutPath}/layout.json`;
};
export default function VirtualScroll(props) {
    const {
        prefetchedData,
        loadMoreItems,
        hasNextPage,
        overwriteFirstTira,
        titolFirstTira,
        titolLogo,
        tipus,
        itemType,
        barraprogres,
        format,
        estil,
        routeType,
        slugRelacio,
        tema,
        mostraCodiEtic,
        routerQuery,
        router,
        evaluatedCondicio,
        urlTira,
        moduleName
    } = props;
    const apiItems = props.apiItems || [];
    const { isWebviewActive } = useProducte();
    const renderItems = () => {
        if (tipus === 'MOSAIC') {
            return (
                <Mosaic
                    items={apiItems}
                    format={format}
                    estil={estil}
                    routeType={routeType}
                    mostraCodiEtic={mostraCodiEtic}
                    slugRelacio={slugRelacio}
                    {...props}
                />
            );
        }

        return apiItems.map((item, index) => {
            const itemTipus = item.tipus ? item.tipus : tipus;
            const field = item.format ? item.format : itemTipus;
            const prograssBar = barraprogres === undefined ? true : barraprogres;
            const fieldComponent = index === 0 && overwriteFirstTira ? overwriteFirstTira : field;
            let newProps = {};
            if (isDebugActive(routerQuery)) {
                newProps.routerQuery = routerQuery;
                newProps.finalData = item;
                newProps.name = 'Tira de Continguts';
                newProps.moduleProps = item;
                newProps.urlDebug = getUrlLayout(router.pathname);
                newProps.evaluatedCondicio = evaluatedCondicio;
                newProps.urlTira = urlTira.replace(/&items_pagina=[^&]*/, '');
            }
            const urlDadesByProducte = getUrlDadesByProducte(item, isWebviewActive, false);

            return field === 'CUSTOM' && components[item.component] ? (
                <React.Fragment key={`tira-${item.component}-${index}`}>
                    {createElement(components[item.component], {
                        ...item.props,
                        urlDadesByProducte: urlDadesByProducte
                    })}
                </React.Fragment>
            ) : components[field] ? (
                <React.Fragment key={item.id}>
                    <Debug {...newProps} />
                    {createElement(components[fieldComponent], {
                        ...item,
                        field,
                        prefetchedData,
                        titolFirstTira,
                        titolLogo,
                        barraprogres: prograssBar,
                        priority: index === 0,
                        itemType,
                        tema,
                        moduleName,
                        urlDadesByProducte: urlDadesByProducte
                    })}
                </React.Fragment>
            ) : null;
        });
    };

    return (
        <InfiniteScroll
            dataLength={apiItems.length}
            next={() => loadMoreItems()}
            hasMore={!!hasNextPage}
            className={styles.virtualScroll}
        >
            {renderItems()}
        </InfiniteScroll>
    );
}

VirtualScroll.defaultProps = {
    className: 'virtualScroll',
    mostraCodiEtic: true,
    slugRelacio: ''
};
