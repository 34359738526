import __includes from 'lodash/includes';
import __isFunction from 'lodash/isFunction';
import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo, useState } from 'react';
import LoginDty from 'components/LoginDty/LoginDty';
import { isEditModeActive } from '@portal-internet/bff';
import { isValidString } from 'utils/utils';

export const UserDelivertyContext = createContext();

const defaultUserData = {
    token: null,
    usuari: null
};

const _parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        const payloadObject = JSON.parse(jsonPayload);
        return payloadObject;
    } catch (error) {
        return null;
    }
};

const _getUserDataFromLocalStorage = () => {
    if (typeof window !== 'undefined') {
        const tokenString = localStorage.getItem('token');
        if (tokenString) {
            const userData = JSON.parse(tokenString);
            return userData;
        }
    }
    return null;
};

const _setUserDataToLocalStorage = (userData) => {
    localStorage.setItem('token', JSON.stringify(userData));
};

const _isUserLogged = () => {
    const userData = _getUserDataFromLocalStorage();
    return !!userData?.token;
};

const _updateIATToken = (userData) => {
    const { iat } = _parseJwt(userData.token);
    localStorage.setItem('iatToken', iat * 1000);
};

const UserDelivertyProvider = (props) => {
    const { children } = props;
    const router = useRouter();
    const [userData, setUserData] = useState({ ...defaultUserData });
    const [isEditModeEnable, setIsEditModeEnable] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [missatge, setMissatge] = useState();
    const REFRESH_TOKEN_TIME_MILISECONDS = 86400000; // 24H
    const isEditMode = isEditModeActive(router?.query);

    useEffect(() => {
        const getUserTokenFromUrlHash = () => {
            const hash = router.asPath.split('#')[1];
            if (hash) {
                const hashParams = new URLSearchParams(hash);
                const dtytoken = hashParams.get('dtytoken');
                if (dtytoken) {
                    const usuari = _parseJwt(dtytoken)?.sub || null;
                    const userData = { token: dtytoken, usuari: usuari };
                    _setUserDataToLocalStorage(userData);
                    router.push(router.asPath.split('#')[0]);
                }
            }
        };
        getUserTokenFromUrlHash();
    }, []);

    useEffect(() => {
        const removeUser = (error) => {
            localStorage.removeItem('token');
            localStorage.removeItem('iatToken');
            setIsEditModeEnable(false);
            setMissatge('ERROR REFRESCANT USUARI');
            setShowLogin(true);
        };

        const refreshToken = async () => {
            const userData = _getUserDataFromLocalStorage();
            if (!userData) return null;

            const prefixApi = window.jitConfig.apiDELIVERTY;
            const pathApi = '/auth/refreshToken';
            const result = await fetch(`${prefixApi}${pathApi}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userData.token
                }
            });
            if (result && result.status === 401) {
                removeUser();
            }
            const data = await result?.json();
            if (result === undefined || result.status !== 200) {
                removeUser(result.error);
            } else {
                const userData = _getUserDataFromLocalStorage();
                if (userData) {
                    userData.token = data.refreshToken;
                    _setUserDataToLocalStorage(userData);
                    _updateIATToken(userData);
                    setIsEditModeEnable(true);
                }
                setUserData(userData);
            }
            return null;
        };

        const isLogged = _isUserLogged();
        if (isEditMode) {
            if (isLogged) {
                refreshToken();
            } else {
                setShowLogin(true);
            }
        }
    }, [isEditMode]);

    const publicApi = useMemo(() => {
        const loginUser = async ({ user, clau }) => {
            const entorn = window?.jitConfig?.entorn;
            if (!isValidString(user) || ('aws_pro' === entorn && !isValidString(clau))) {
                setMissatge('Error, usuari o contrasenya incorrectes');
                return;
            }
            const prefixApi = window.jitConfig.apiDELIVERTY;
            const pathApi = `/auth/login`;
            const dataLogin = { login: user, clau: clau };

            const response = await fetch(`${prefixApi}${pathApi}`, {
                method: 'POST',
                body: JSON.stringify(dataLogin),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            console.log(data);
            if (data?.token !== undefined && data?.usuari !== undefined) {
                _setUserDataToLocalStorage(data);
                setShowLogin(false);
                setUserData(data);
                setIsEditModeEnable(true);
            } else {
                setMissatge('Error, usuari o contrasenya incorrectes');
            }
        };

        return {
            userData,
            loginUser,
            isEditModeEnable
        };
    }, [userData, isEditModeEnable]);

    return (
        <UserDelivertyContext.Provider value={publicApi}>
            {showLogin && <LoginDty missatge={missatge}></LoginDty>}
            {children}
        </UserDelivertyContext.Provider>
    );
};

export default UserDelivertyProvider;
