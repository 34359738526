import { getDataNeta, getPageTitle } from '@portal-internet/core';
import __isEmpty from 'lodash/isEmpty';
import __isNumber from 'lodash/isNumber';
import __isUndefined from 'lodash/isUndefined';
import { dataResources } from 'utils/constants';

const NOINFORMAT = 'No Informat';

const _defineDeviceUID = (deviceUid) => {
    if (deviceUid) {
        window.getDeviceUID =
            window.getDeviceUID ||
            function () {
                return deviceUid;
            };
    }
};

const _val = (value) => {
    return typeof value !== 'undefined' && value.toString().toLowerCase() !== 'no informat' ? value : '';
};

const _getTempsPublicacio = (value) => {
    let dat,
        dat2 = new Date();
    if (value !== '' && typeof value !== 'undefined') {
        const [data, time] = value?.split(' ');
        let [d, m, y] = data.split(/\D/);
        let [hora, min] = time.split(':');

        dat = new Date(y, m - 1, d, hora, min);
        return Math.floor(
            (Date.UTC(dat2.getFullYear(), dat2.getMonth(), dat2.getDate(), dat2.getHours(), dat2.getMinutes()) -
                Date.UTC(dat.getFullYear(), dat.getMonth(), dat.getDate(), dat.getHours(), dat.getMinutes())) /
                (1000 * 60)
        ).toString();
    } else {
        return '';
    }
};

const _getHoraMinuts = (value) => {
    if (value !== '' && typeof value !== 'undefined' && value !== 'No Informat') {
        const [data, time] = value?.split(' ');
        let [h, m] = time?.split(':');
        if (h || m) {
            return h + ':' + m;
        }
    }
    return false;
};

const _getCurrentUrl = (router) => {
    let url = router.asPath,
        indexParams = url.indexOf('?');
    url = indexParams > 0 ? url.substring(0, indexParams) : url;
    const indexHash = url.indexOf('#');
    url = indexHash > 0 ? url.substring(0, indexHash) : url;
    return 'https://' + dataResources.domainCCMA + url;
};

const _getCurrentFullUrl = (router) => {
    const url = router.asPath;
    return 'https://' + dataResources.domainCCMA + url;
};

const _getURLQueryString = (router) => {
    const indexParams = router.asPath.indexOf('?');
    return indexParams > 0 ? router.asPath.substring(indexParams + 1) : '';
};

const _getLoginState = (isAuthenticated) => {
    let estat = 'Sense fer login';
    if (isAuthenticated) {
        estat = 'Amb login CCMA';
    }
    return estat;
};

const _getFormats = (formats) => {
    if (__isUndefined(formats) || __isEmpty(formats)) return formats;

    return [
        ...new Set(
            formats
                .filter((format) => format.main === true)
                ?.map((format) => format.desc)
                ?.concat(formats.map((format) => format.desc))
        )
    ]?.join(',');
};

const _getIdUsuari = (id) => {
    return id ? btoa(id) : '';
};

const _getFileInfo = (props) => {
    const { tipus, isWebviewActive, evar1, evar3, evar2, evar7, evar8, canal, id, data_publicacio, formats, redactor, pagename } =
        props;
    let fileInfo = {};
    const WEB_PROGRAMA = 'Web de programa',
        APPMOBILS = 'Aplicacions Mòbils',
        APP3CATPLAY = 'App: 3cat';
    if (tipus === 'item') {
        fileInfo = {
            pagename: _getPageNameItem(props),
            titol: _getPageNameItem(props, '3Cat') || NOINFORMAT,
            evar3: ['X3', 'S3', 'SX3'].includes(canal) ? 'SX3' : WEB_PROGRAMA,
            evar5: WEB_PROGRAMA,
            evar8: evar8 || NOINFORMAT,
            evar14: id || NOINFORMAT,
            evar19: data_publicacio || NOINFORMAT,
            evar69: _getFormats(formats),
            evar86: redactor || NOINFORMAT,
            dataPublicacio: data_publicacio || NOINFORMAT
        };

        if (isWebviewActive) {
            fileInfo = { ...fileInfo, evar1: APPMOBILS, evar2: APP3CATPLAY };
        }

        return fileInfo;
    } else if (tipus === 'mapaweb') {
        return {
            pagename: pagename,
            titol: pagename,
            evar1: isWebviewActive ? APPMOBILS : evar1,
            evar2: isWebviewActive ? APP3CATPLAY : evar2,
            evar7: isWebviewActive ? `${evar7} app` : evar7
        };
    } else if (tipus === 'programa') {
        fileInfo = {
            evar3: ['X3', 'S3', 'SX3'].includes(canal) ? 'SX3' : evar3,
            evar69: _getFormats(formats)
        };
        if (isWebviewActive) {
            fileInfo = { ...fileInfo, evar1: APPMOBILS, evar2: APP3CATPLAY };
        }

        return fileInfo;
    }
    return {};
};

const _getPageNameItem = (props, pportal) => {
    const { evar8, titol } = props;

    let pageName = [evar8, titol, pportal].join(' - ');
    pageName = pageName.replace(/^( - )+/, '');
    pageName = pageName.replace(/( - )$/, '');
    return pageName;
};

const _getEvarsByWebview = (props) => {
    const { evar7, isWebviewActive } = props;

    if (!isWebviewActive) return props;

    return {
        evar7: evar7 && `${evar7} app`
    };
};

const setGlobalEvar = (index, eVar) => {
    window.globalEvars = window.globalEvars || {};
    window.globalEvars[index] = eVar;
};

const setDigitalData = (props) => {
    let fileInfo;
    const {
        pagename,
        titol,
        prefix,
        sufix,
        evar1,
        evar2,
        evar3,
        evar4,
        evar5,
        evar8,
        evar9,
        evar14,
        evar16,
        evar17,
        evar19,
        evar32,
        evar40,
        evar41,
        evar69,
        evar83,
        evar84,
        evar86,
        agrupacions,
        dataPublicacio,
        status,
        isAuthenticated,
        id,
        deviceUid,
        viewport,
        router,
        tipus,
        tiraOrigen,
        tiraTitles,
        paginaAnterior
    } = props;
    const { evar7 } = _getEvarsByWebview(props);

    fileInfo = _getFileInfo(props);

    const _getValor = (vParam, vItem = '', vDefecte = '') => {
        if (!__isEmpty(vParam) || __isNumber(vParam)) return _val(vParam);
        if (vItem !== NOINFORMAT && (!__isEmpty(vItem) || __isNumber(vItem))) return _val(vItem);
        return vItem === NOINFORMAT ? vItem : vDefecte;
    };

    if (tipus == 'errorPage' && !__isEmpty(window) && !__isEmpty(window.s)) {
        window.s.pageType = 'errorPage';
    }

    return {
        user: {
            userLogin: _getLoginState(isAuthenticated), // 'Sense fer login' || 'Amb login CCMA' || 'Amb login Super3'
            idUsuari: _getIdUsuari(id), // id del registre de la ccma evar71 //REVISAR
            idBigData: deviceUid // S'agafa de Matr
        },
        page: {
            pageName: tipus == 'errorPage' ? '' : _getValor(fileInfo?.pagename, getPageTitle(prefix, titol)),
            actualPageName: _getValor(fileInfo?.titol, getPageTitle(prefix, titol)),
            viewport: viewport,
            web: _getValor(evar1, fileInfo?.evar1, 'CCMA'),
            seccio: _getValor(evar2, fileInfo?.evar2, '3cat'),
            subseccio: _getValor(fileInfo?.evar3, evar3, '3cat'), // Volem que pugui retornar el camp com a 'No informat'
            subseccioNivell1: _getValor(evar4, fileInfo?.evar4, NOINFORMAT),
            subseccioNivell2: _getValor(evar5, fileInfo?.evar5, NOINFORMAT),
            URL: _getCurrentUrl(router), //si per mapaweb la canviem, no tenim la URL actual
            tipusPagina: tipus == 'errorPage' ? 'errorPage' : _getValor(evar7, fileInfo?.evar7, NOINFORMAT),
            programaWeb: _getValor(evar8, fileInfo?.evar8, NOINFORMAT),
            areaTematicaWeb: _getValor(evar9), //PREGUNTAR HIKE SPLIT!! es pot fer l'split des de launch? s.eVar9=s.eVar9.split(",")[0];
            temaProg: _getValor(fileInfo?.evar17, _getFormats(evar17), NOINFORMAT),
            idDeliverty: _getValor(evar14, fileInfo?.evar14, NOINFORMAT).toString(),
            class_3cat: _getValor(evar16, fileInfo?.evar16, NOINFORMAT),
            dataEmissio: _getValor(getDataNeta(_getValor(evar19, fileInfo?.evar19)), NOINFORMAT),
            cercadorKW: window.globalEvars?.eVar40 || _val(evar40),
            cercadorResultats: window.globalEvars?.eVar41 || _val(evar41),
            consent: window.globalEvars?.eVar89 || NOINFORMAT,
            origenClic: tiraOrigen || NOINFORMAT,
            relacionats: tiraTitles || NOINFORMAT,
            formatva: _getValor(fileInfo?.evar69, _getFormats(evar69), NOINFORMAT),
            agrupadoraPrograma: _getValor(fileInfo?.evar32, _getFormats(evar32), NOINFORMAT),
            categoriaAgrupadora: _getValor(fileInfo?.agrupacions, _getFormats(agrupacions), NOINFORMAT),
            campanyesInternes: _val(evar83),
            lectorPantalla: _val(evar84),
            autor: _getValor(evar86, fileInfo?.evar86),
            horaEmissio: _getValor(_getHoraMinuts(_getValor(evar19, fileInfo?.evar19)), NOINFORMAT),
            contingutMultimedia: 'PER_DEFINIR',
            urlSencera: _getCurrentFullUrl(router),
            queryStringUrl: _getURLQueryString(router),
            tempsPublicacio: _getTempsPublicacio(_getValor(dataPublicacio, fileInfo?.dataPublicacio)),
            status: _val(status)
        }
    };
};

const setGlobalEvars = (digitalData) => {
    setGlobalEvar('eVar63', digitalData.user.userLogin);
    setGlobalEvar('eVar71', digitalData.user.idUsuari);
    setGlobalEvar('eVar87', digitalData.user.idBigData);
    _defineDeviceUID(digitalData.user.idBigData);
};

const windowSetGlobalEvar = (evar, params) => {
    window.getGlobalEvar =
        window.getGlobalEvar ||
        function (evar) {
            window.globalEvars = window.globalEvars || {};
            window.s = window.s || {};
            window.globalEvars[evar] = window.globalEvars[evar] || window.s[evar];
            return window.globalEvars[evar];
        };

    let value = '';
    switch (evar) {
        case 'eVar71':
            value = _getIdUsuari(params?.id);
            break;
        case 'eVar63':
            value = _getLoginState(params?.isAuthenticated);
            break;
    }

    setGlobalEvar(evar, value);
};

const initGlobalMatr = (matr) => {
    window.getDeviceUID = undefined;
    _defineDeviceUID(matr?.deviceUid);
};

export { setDigitalData, setGlobalEvar, setGlobalEvars, initGlobalMatr, windowSetGlobalEvar };
