import clsx from 'clsx';
import __isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import { dataResources } from 'utils/constants';
import styles from './codiespectador.module.scss';

const { basePath } = dataResources;

export default function CodiEspectador(props) {
    const { codi, alt, mida, className, title, estil } = props;

    let codiEdat = codi?.replace('PUCE_', '').replace('CE_', '').replace(/^0+/, '');

    if (codiEdat === 'IN') {
        codiEdat = 'TP';
    }

    const altEdat = !__isEmpty(alt) && !isNaN(codiEdat) ? codiEdat + ' anys' : alt;

    const styleIcona = clsx({
        [styles[estil]]: !__isEmpty(estil),
        [className]: !__isEmpty(className)
    });

    let src = basePath + '/_resources/icones/codisEspectador/codisEspectador' + codiEdat + '.svg?v=1.2501.7';

    const imageLoader = ({ src }) => {
        return `${src}`;
    };

    return (
        codiEdat &&
        codiEdat !== 'TP' && (
            <span className={styleIcona} data-testid={`codi-espectador-${codiEdat}`} aria-label={'Edat recomanada ' + altEdat}>
                <Image
                    loader={imageLoader}
                    width={codiEdat === 'AD' ? mida * 1.6 : mida}
                    height={mida}
                    alt={altEdat}
                    src={src}
                    unoptimized={true}
                    aria-hidden={true}
                />
            </span>
        )
    );
}

CodiEspectador.defaultProps = {
    codi: '',
    alt: '',
    mida: '20',
    className: '',
    title: ''
};
