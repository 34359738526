import { useEffect, useState, useContext } from 'react';
import { ServicesContext } from 'context/ServicesProvider';

const useIsServiceEnabled = (serviceId, verbose) => {
    const { getServiceStatus, getServiceGrant } = useContext(ServicesContext);
    const [isServiceEnabled, setIsServiceEnabled] = useState();
    const [serviceDetails, setServiceDetails] = useState({});

    useEffect(() => {
        const [serviceStatus, serviceStatusDetails] = getServiceStatus(serviceId);
        const [serviceGrant, serviceGrantDetails] = getServiceGrant(serviceId, true);
        setIsServiceEnabled(serviceStatus && serviceGrant);
        setServiceDetails({
            status: {
                ok: serviceStatus,
                details: serviceStatusDetails
            },
            grants: {
                granted: serviceGrant,
                details: serviceGrantDetails
            }
        });

        if (!serviceStatus) {
            console.log(`ATENCIÓ! "${serviceId}" Service Status: NOK`);
        }
    }, [serviceId]);

    if (verbose) {
        return [isServiceEnabled, serviceDetails];
    }

    return isServiceEnabled; // Tres possibles estats: undefined, false, true
};

export default useIsServiceEnabled;
