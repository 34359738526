import { createContext, useEffect, useState } from 'react';
import { useRedlMetadata } from 'hooks/useRedlMetadata';
import { useRouter } from 'next/router';
import __cloneDeep from 'lodash/cloneDeep';

export const RedlContext = createContext({});

const RedlProvider = (props) => {
    const [redlDatabaseStructure, setRedlDatabaseStructure] = useState(null);
    const [zoom, setZoom] = useState(100);
    const [showRightSidePanel, setShowRightSidePanel] = useState(null);
    const [showLayout, setShowLayout] = useState(false);
    const [hidePreview, setHidePreview] = useState(false);
    const [toastText, setToastText] = useState('');
    const router = useRouter();
    const { getRedlPageRawLayout } = useRedlMetadata(router.pathname);
    const redlPageRawLayout = getRedlPageRawLayout();
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        if (redlPageRawLayout?.structure && !redlDatabaseStructure) {
            setRedlDatabaseStructure(__cloneDeep(redlPageRawLayout.structure));
        }
    }, [redlPageRawLayout, redlDatabaseStructure]);

    useEffect(() => {
        document.getElementById('__mainContent').style.zoom = `${zoom}%`;
    }, [zoom]);

    const value = {
        zoom,
        setZoom,
        showRightSidePanel,
        setShowRightSidePanel,
        showLayout,
        setShowLayout,
        hidePreview,
        setHidePreview,
        redlDatabaseStructure,
        toastText,
        setToastText,
        scrollPercentage,
        setScrollPercentage
    };

    return (
        <RedlContext.Provider value={value}>
            {props.children}
        </RedlContext.Provider>
    );
};

export default RedlProvider;