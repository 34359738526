import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Pastilla from 'components/3cat/Pastilla';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getPropsSwiper } from './funcions';
import styles from './slider.module.scss';

export default function SliderPastilles(props) {
    const { items, format } = props;
    const router = useRouter();
    const pathName = router.pathname;
    const slug = pathName?.split('/')?.slice(-1)?.toString();
    const navigationPrev = useRef(null);
    const navigationNext = useRef(null);
    const swiper = useRef(null);
    const [classPos] = useState('');
    const [swiperInit, setSwiperInit] = useState(false);
    let resizeTimeOut;
    const stylesSwiper = clsx({
        [styles.innerSlider]: true,
        [styles.fixHeight]: true,
        [styles.dreta]: classPos === 'openRight',
        [styles.esquerra]: classPos === 'openLeft'
    });

    const stylesModul = clsx({
        [styles.init]: swiperInit,
        [styles.slider]: true,
        [styles.cPastilla]: true
    });

    const [activeElement, setActiveElement] = useState(null);

    const _buildSlide = (element, i) => {
        return slug !== element?.slug && element?.visible?.includes('web') ? (
            <SwiperSlide key={element.id} className={styles.fixSlideWidthPastilla} virtualIndex={i}>
                <Enllac id={element.slug} type='web_3cat_seccio'>
                    <Pastilla
                        data={element}
                        isActive={element.id === activeElement}
                        onSelect={(id) => setActiveElement(id)}
                    ></Pastilla>
                </Enllac>
            </SwiperSlide>
        ) : null;
    };

    const _iterateItems = () => {
        return !__isEmpty(items) ? Array.from(items).map((element, i) => _buildSlide(element, i)) : null;
    };

    const _renderSwiper = () => {
        const propsSwiper = getPropsSwiper(format);
        return (
            <Swiper
                {...propsSwiper}
                ref={swiper}
                navigation={{
                    prevEl: navigationPrev.current,
                    nextEl: navigationNext.current
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrev.current;
                    swiper.params.navigation.nextEl = navigationNext.current;
                }}
                onAfterInit={() => {
                    setSwiperInit(true);
                }}
                modules={[Navigation, FreeMode]}
                className={`${stylesSwiper}`}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            swiper.update();
                        }, 100);
                    });
                }}
            >
                <button ref={navigationPrev} className={styles.anterior}>
                    <Icona icona='fletxa_simple_esquerra' alt='Anterior' isBlanc={false} mida={18} />
                </button>
                <button ref={navigationNext} className={styles.seguent}>
                    <Icona icona='fletxa_simple_dreta' alt='Següent' isBlanc={false} mida={18} className={styles.iconadreta} />
                </button>
                {_iterateItems()}
            </Swiper>
        );
    };

    return <div id="pastillas-slider" className={stylesModul}>{_renderSwiper()}</div>;
}

SliderPastilles.defaultProps = {
    items: {},
    format: 'pastilla'
};
