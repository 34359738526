import __find from 'lodash/find';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import __isString from 'lodash/isString';
import __map from 'lodash/map';
import __merge from 'lodash/merge';
import __pick from 'lodash/pick';
import __reduce from 'lodash/reduce';
import __set from 'lodash/set';
import __union from 'lodash/union';
import { printError } from '@portal-internet/core'; // logger

global.optimizr = {};
global.optimizr.enabled = true;
global.optimizr.verboseTraces = false;
global.optimizr.optimizeDataSpecs = [];
global.optimizr.tipusImatgePredef = {};

const setRepeatOptimizeDataSpec = (globalSpec, pickSpec) => {
    const { root, rootPickPaths, items, itemsPickPaths, itemsImatgesPick } = pickSpec;
    globalSpec.pickSpec.root = root;
    globalSpec.pickSpec.rootPickPaths = __union(globalSpec.pickSpec.rootPickPaths, rootPickPaths);
    globalSpec.pickSpec.items = items;
    globalSpec.pickSpec.itemsPickPaths = __union(globalSpec.pickSpec.itemsPickPaths, itemsPickPaths);

    itemsImatgesPick.forEach((imatgesPick) => {
        let find = false;
        globalSpec.pickSpec.itemsImatgesPick = globalSpec.pickSpec.itemsImatgesPick.map((globalImatgesPick) => {
            if (globalImatgesPick.imatgesPath === imatgesPick.imatgesPath) {
                find = true;
                globalImatgesPick.pickTipus = __union(
                    globalImatgesPick.pickTipus.split(' '),
                    imatgesPick.pickTipus.split(' ')
                ).join(' ');
            }
            return globalImatgesPick;
        });
        if (!find) {
            globalSpec.pickSpec.itemsImatgesPick.push({ ...imatgesPick });
        }
    });
    globalSpec._version = globalSpec._version + 1;
};

const setOptimzeDataSpec_A = (key, tipus, format, pickSpec) => {
    let isFound = false;
    global.optimizr.optimizeDataSpecs.map((globalSpec) => {
        if (
            globalSpec.key === key &&
            (tipus ? globalSpec.tipus === tipus : true) &&
            (format ? globalSpec.format === format : true)
        ) {
            isFound = true;
            setRepeatOptimizeDataSpec(globalSpec, pickSpec);
        }
    });

    if (!isFound) {
        global.optimizr.optimizeDataSpecs.push({
            key,
            tipus,
            format,
            pickSpec,
            _version: 1
        });
    }
};

const setOptimzeDataSpec_B = (key, field, pickSpec) => {
    let isFound = false;
    global.optimizr.optimizeDataSpecs.forEach((globalSpec) => {
        if (globalSpec.key === key && (field ? globalSpec.field === field : true)) {
            isFound = true;
            setRepeatOptimizeDataSpec(globalSpec, pickSpec);
        }
    });
    if (!isFound) {
        global.optimizr.optimizeDataSpecs.push({
            key,
            field,
            pickSpec,
            _version: 1
        });
    }
};

const setOptimzeDataSpec_C = (key, pickSpec) => {
    let isFound = false;
    global.optimizr.optimizeDataSpecs.forEach((globalSpec) => {
        if (globalSpec.key === key) {
            isFound = true;
            setRepeatOptimizeDataSpec(globalSpec, pickSpec);
        }
    });
    if (!isFound) {
        global.optimizr.optimizeDataSpecs.push({
            key,
            pickSpec,
            _version: 1
        });
    }
};

const setOptimizeDataSpec = (optimizeDataSpec) => {
    const { key, tipus, format, field, pickSpec } = optimizeDataSpec;

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces)
    //     console.debug(
    //         `[OPTIMIZR] 1>setOptimizeDataSpec, key: '${key}', tipus: '${tipus}', format: '${format}', field: '${field}'`
    //     );
    if (tipus !== undefined && typeof tipus === 'string') {
        tipus.split(' ').forEach((singleTipus) => {
            setOptimzeDataSpec_A(key, singleTipus, format, pickSpec);
        });
    } else if (field !== undefined && typeof field === 'string') {
        field.split(' ').forEach((singleField) => {
            setOptimzeDataSpec_B(key, singleField, pickSpec);
        });
    } else {
        setOptimzeDataSpec_C(key, pickSpec);
    }
};

const setOptimizeDataSpecs = (optimizeDataSpecs) => {
    if (!global.optimizr.enabled) return;

    optimizeDataSpecs.forEach((optimizeDataSpec) => {
        setOptimizeDataSpec(optimizeDataSpec);
    });

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces) console.debug('[OPTIMIZR] S>setOptimizeDataSpecs:', global.optimizr.optimizeDataSpecs);
};

const setOptimizeImageSpecs = (tipusImatgePredef) => {
    if (!global.optimizr.enabled) return;

    global.optimizr.tipusImatgePredef = tipusImatgePredef;

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces) console.debug('[OPTIMIZR] S>setOptimizeImageSpecs:', global.optimizr.tipusImatgePredef);
};

const _pickRootData = (data, root, pickPaths, memo) => {
    return __reduce(
        pickPaths,
        (memo, pickPath) => {
            const path = `${root}.${pickPath}`;
            return __merge(memo, __pick(data, path));
        },
        memo
    );
};

const _pickItemsData = (data, rawItemsPath, pickPaths, memo) => {
    const rawItems = __get(data, rawItemsPath);
    const pickedItems = __map(rawItems, (item) => {
        return __pick(item, pickPaths);
    });
    memo = __set(memo, rawItemsPath, pickedItems);
    return memo;
};

const _getImatge = (imatges, mides) => {
    if (__isEmpty(imatges) || !Array.isArray(imatges)) {
        return imatges || [];
    }
    let pickedImatge = [];
    try {
        const candidates = __map(mides, (mida) =>
            __find(imatges, (img) => (__isEmpty(mida.rel_name) || img.rel_name === mida.rel_name) && img.mida === mida.mida)
        ).filter((img) => img);

        if (!__isEmpty(candidates)) {
            //TODO : filtrem lo que ens retorna les imatges. Això vindrà fet a la API
            const { rel_name, mida, text } = candidates[0];
            pickedImatge = [{ rel_name, mida, text }];
        }
    } catch (error) {
        printError(error, 'jitDataOptimizr.js > _getImatge', error.stack);
    }
    return pickedImatge;
};

const _pickImatgesData = (data, rawItemsPath, itemsImatgesPick, memo) => {
    const rawItems = __get(data, rawItemsPath) || [];

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces) console.debug(`[OPTIMIZR] rawItems:`, rawItems);
    const pickedItems = __map(rawItems, (item, index) => {
        let allPickedImatges = {};

        itemsImatgesPick.forEach((imatgesPick) => {
            const allImatges = __get(item, imatgesPick.imatgesPath) || [];

            if (!__isEmpty(allImatges)) {
                let singlePickedImatges = [];
                if (__isString(imatgesPick.pickTipus)) {
                    /*console.warn(
                        "DEPRECATED imatgesPick.pickTipus ha de ser tipus array d'objectes. S'HA DE MODIFICAR!! Per dubtes, parlar amb EPUB, rebut:",
                        imatgesPick.pickTipus
                    );*/
                    const tipusImatgePredef = global.optimizr.tipusImatgePredef;
                    imatgesPick.pickTipus.split(' ').forEach((pickTipus) => {
                        const mides = tipusImatgePredef[pickTipus] || tipusImatgePredef['hbbtv_default'];
                        singlePickedImatges = __union(singlePickedImatges, _getImatge(allImatges, mides));
                    });
                } else {
                    imatgesPick.pickTipus.forEach((mides) => {
                        singlePickedImatges = __union(singlePickedImatges, _getImatge(allImatges, mides));
                    });
                }

                __set(allPickedImatges, imatgesPick.imatgesPath, singlePickedImatges);
            }
        });

        return allPickedImatges;
    });

    const mergeableItems = __set({}, rawItemsPath, pickedItems);
    memo = __merge(memo, mergeableItems);

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces) console.debug(`[OPTIMIZR] _pickImatgesData > pickedItems:`, pickedItems);
    // if (global.optimizr.verboseTraces) console.debug(`[OPTIMIZR] _pickImatgesData > mergeableItems:`, mergeableItems);
    // if (global.optimizr.verboseTraces) console.debug(`[OPTIMIZR] _pickImatgesData > memo:`, memo);
    return memo;
};

const getOptimizedDataWithPickSpec = (pickSpec, data, memo) => {
    if (pickSpec.root && pickSpec.rootPickPaths) {
        // @SupressWarnings("S125")
        // console.debug('[OPTIMIZR] g>spec processing _pickRootData...');
        memo = _pickRootData(data, pickSpec.root, pickSpec.rootPickPaths, memo);
    }
    if (pickSpec.items && pickSpec.itemsPickPaths) {
        // @SupressWarnings("S125")
        // console.debug('[OPTIMIZR] g>spec processing _pickItemsData...');
        memo = _pickItemsData(data, pickSpec.items, pickSpec.itemsPickPaths, memo);
    }
    if (pickSpec.itemsImatgesPick) {
        // @SupressWarnings("S125")
        // console.debug(`[OPTIMIZR] g>Imatges pick:`, _itemsImatgesPick);
        memo = _pickImatgesData(data, pickSpec.items, pickSpec.itemsImatgesPick, memo);
    }
    return memo;
};

const dataOptimizer = (optimizeDataSpecs, data, propValue) => {
    const optimizeDataSpec = optimizeDataSpecs.find(({ key }) => key === propValue.dataId);
    return optimizeDataSpec ? getOptimizedDataWithPickSpec(optimizeDataSpec.pickSpec, data, {}) : data;
};

const getOptimizedData = (queryKey, data) => {
    const [key, { tipus, format, field }] = queryKey;
    const specs = global.optimizr.optimizeDataSpecs;

    let result = {};

    // @SupressWarnings("S125")
    // if (global.optimizr.verboseTraces) console.debug('[OPTIMIZR] G>ALL specs:', specs);
    // console.debug(`[OPTIMIZR] G>RAW queryKey:`, queryKey);
    // console.debug(`[OPTIMIZR] G>PARSED: key='${key}', tipus='${tipus}', format='${format}', field='${field}', nom='${nom}'`);
    //console.log('global.optimizr', global.optimizr)
    if (global.optimizr.enabled) {
        result = __reduce(
            specs,
            (memo, spec) => {
                if (
                    spec.key === key &&
                    (spec.tipus && tipus ? spec.tipus.includes(tipus) : true) &&
                    (spec.format && format ? spec.format.includes(format) : true) &&
                    (spec.field && field ? spec.field.includes(field) : true)
                ) {
                    // @SupressWarnings("S125")
                    // console.debug(`[OPTIMIZR] g>MATCHING spec:`, spec.key, spec.tipus);
                    const pickSpec = spec.pickSpec;
                    memo = getOptimizedDataWithPickSpec(pickSpec, data, memo);
                }
                return memo;
            },
            {}
        );
    }

    // @SupressWarnings("S125")
    // console.debug('[OPTIMIZR] G>axios:', data);
    if (__isEmpty(result)) {
        result = data;
        // @SupressWarnings("S125")
        // console.debug('[OPTIMIZR] G>result = axios');
    } else {
        // @SupressWarnings("S125")
        // console.debug('[OPTIMIZR] G>result:', result);
    }
    return result;
};

export { dataOptimizer, getOptimizedData, getOptimizedDataWithPickSpec, setOptimizeDataSpecs, setOptimizeImageSpecs };
