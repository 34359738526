import { getDurada, removeHtmlTags } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import Icona from '../ui/Icona';
import styles from './slider.module.scss';

const _getTipologiaRelacionada = (e) => {
    let tipologiaRelacionada = null;
    if (e.programes_tv) tipologiaRelacionada = e.programes_tv[0]?.tipologia;
    else if (e.programes) {
        tipologiaRelacionada = e.programes[0]?.tipologia || e.programes?.programa[0]?.tipologia;
    }
    return tipologiaRelacionada;
};
const _getRoute = (isWebviewActive, isKeyframe, tipus, route, imageRoute) => {
    return isWebviewActive && isKeyframe && (tipus === 'ESTAS_VEIENT' || imageRoute) ? { ...route, format: 'PLAYER' } : route;
};
const _getRouteHref = (tipus, url, tipologia) => {
    const isDesFoto = tipus === 'midalliure' || tipus === 'desFoto';
    if (tipus === 'DESTACAT' || ((isDesFoto || tipus === 'seccions') && tipologia === 'DTY_DESTACAT'))
        return url?.replace(/^\/\/www./i, 'https://www.') || '#';
    return ['WCR_PROGRAMA', 'PTVC_PROGRAMA', 'PTVC_SECCIO', 'DTY_DESTACAT'].includes(tipologia) ? null : url;
};
const _getMrec = (e) => {
    let mrecProperties = {
            mrecid: e.llista_id,
            mrecm: 'htmltwig_mrec',
            mrecp: e.ordre ? e.ordre : '',
            mrecdf: e.usuari_id === 'default',
            mrect: e.tipus_test || ''
        },
        height = Math.round($(document).height()),
        yPosition = _calculateYPosition(),
        mrecParamProperties = { mrech: height, mrecy: yPosition };
    const mrecDades = JSON.stringify({ ...mrecProperties, ...mrecParamProperties });

    return mrecDades ? window.btoa(mrecDades) : '';
};
const _calculateYPosition = () => {
    let bodyRect = document.body.getBoundingClientRect(),
        elemRect = document.body.querySelector("section[aria-labelledby*='Recomanatsperatu'")?.getBoundingClientRect();
    return Math.round(elemRect?.top - bodyRect.top);
};

const _getInfo = (e, tipus) => {
    const ce_id = __isEmpty(e.codis_etics) ? '' : e.codis_etics[0].id;
    const ce_de = __isEmpty(e.codis_etics) ? '' : e.codis_etics[0].desc;
    let imgAlt = e.imatges ? e.imatges[1]?.alt || e.imatges[1]?.titol : '';
    let titol = e.tipologia === 'DTY_VIDEO_MM' ? e.titol : e.titol_promo || e.titol || e.alt || e.desc;
    return e
        ? {
              avantitol: e.avantitol || e.programes_tv?.[0].desc || e.programes_radio?.[0].desc,
              titol: titol,
              imgAlt: imgAlt == '' && tipus === 'seccions' ? e.desc : imgAlt,
              durada: e.durada,
              entradeta: removeHtmlTags(e.entradeta_promo || e.entradeta),
              etiqueta: e.etiquetes?.find((et) => et.main === true)?.desc || e.etiqueta,
              link: e.id,
              imatge: e.imatges,
              imatgesPrograma: e.programes_tv?.[0].imatges || e.programes_radio?.[0].imatges,
              codi_etic: ce_id,
              codi_alt: ce_de,
              tipologia: e.tipologia,
              canals: e.canals,
              tipologianom: e.nom || e.programes?.[0]?.nom || e.programes?.programa?.[0]?.nom,
              tipologiaRelacionada: _getTipologiaRelacionada(e),
              idRelacionat: e.programes?.[0]?.id || e.programes?.programa?.[0]?.id,
              nomprograma: e.programes?.[0]?.nom_bonic || e.programes?.programa?.[0]?.nom_bonic,
              nombonic: e.nombonic,
              url: e.url,
              domini: e.domini,
              permatitle: e.permatitle,
              mrecInfo: tipus === 'RECOMANEM' ? _getMrec(e) : ''
          }
        : false;
};

export default function Slide(props) {
    const {
        element,
        tipus,
        midaImatge,
        nomPrograma,
        slugRelacio,
        routeType,
        format,
        isFilter,
        isSeccio,
        isMidaLliure,
        isAudio,
        showProgramTitle,
        isWebviewActive,
        estilModul,
        showLogoPrograma,
        isKeyframe,
        icona,
        isDirecte,
        isRanking,
        hideAvantitol,
        marcatgeAudienciesClick,
        dataTestId
    } = props;

    const isDirecteFutur = element.permis_directe; // TODO:revisar d'on ve aquesta info
    const finalInfo = _getInfo(element, tipus);
    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');
    const [hasBeenHovered, setHasBeenHovered] = useState(false);

    const styleIcona = clsx({
        [styles.overIcon]: true,
        [styles.iconaGran]: midaImatge === 'web_postergran'
    });

    const {
        link,
        entradeta,
        codi_etic,
        codi_alt,
        imatge,
        imatgesPrograma,
        etiqueta,
        durada,
        nombonic,
        tipologianom,
        tipologia,
        tipologiaRelacionada,
        idRelacionat,
        permatitle,
        mrecInfo
    } = finalInfo;

    const itemAvantitol = finalInfo.avantitol,
        itemTitol = finalInfo.titol,
        itemSlug = nombonic || permatitle || itemTitol,
        slugPrograma = nomPrograma || finalInfo.nomprograma,
        percentatgeVist = element.percentatge || 0,
        route = {
            id: finalInfo.link,
            tipologia: tipologia,
            canals: finalInfo.canals,
            domini: finalInfo.domini || 'TVC',
            slug: element.slug || itemSlug,
            slugPrograma: slugPrograma,
            slugRelacio: slugRelacio,
            categoria: element.categoria,
            idRelacio: idRelacionat,
            tipologiaRelacionada,
            type: routeType || element.type,
            href: _getRouteHref(tipus, finalInfo.url, tipologia),
            format: format
        };
    if (isWebviewActive && mrecInfo) route.hash = `mrec=${mrecInfo}`;
    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.itemHover]: true }));
            }, 200);
        }
    }, [isHover]);

    if (__isEmpty(itemTitol) && __isEmpty(imatge) && __isEmpty(finalInfo.link)) {
        return false;
    }

    const _showEntradeta = () => {
        return (
            <>
                {!hideAvantitol && !__isEmpty(itemAvantitol) && showProgramTitle && !_esticVeientPrograma() && (
                    <span className={styles.avantitol} data-testid={'slide-avantitol'}>
                        {itemAvantitol}
                    </span>
                )}
                {!__isEmpty(itemTitol) && (
                    <h3 className={styles.titol} data-testid={'slide-titol'}>
                        {itemTitol}
                    </h3>
                )}
                {!__isEmpty(entradeta) && (
                    <div
                        className={styles.entradeta}
                        data-testid={'slide-entradeta'}
                        dangerouslySetInnerHTML={{ __html: entradeta }}
                    ></div>
                )}
            </>
        );
    };
    const _showProgressBar = (estil) => {
        let estilBarra;
        if (estil) {
            estilBarra = `${styles.progress} ${estil}`;
        } else {
            estilBarra = `${styles.progress}`;
        }
        return (
            !isAudio &&
            tipus != 'DIRECTES_FUTUR' &&
            tipus !== 'personatges' && <ProgressBar className={estilBarra} progress={percentatgeVist} />
        );
    };
    const _showDurada = () => {
        return (
            (midaImatge === 'web_postergran' ||
                midaImatge === 'web_posterpetit' ||
                midaImatge === 'web_keyframe' ||
                _esticVeientPrograma()) &&
            durada && (
                <>
                    <span className={styles.ico_clock}>
                        <Icona icona='rellotge' isBlanc={true} alt='Icona rellotge' ariaHidden={true} />{' '}
                    </span>
                    <span className={styles.durada} data-testid={`slide-durada`}>
                        <span className='sr-only'>Durada: </span>
                        {getDurada(durada)}
                    </span>
                </>
            )
        );
    };
    const _showCodiEspectador = () => {
        if (__isEmpty(codi_etic)) {
            return null;
        }
        return <CodiEspectador className={styles.codiespectador} codi={codi_etic} alt={codi_alt} mida={20} />;
    };
    const _showBlurImage = () => {
        return (
            isAudio && (
                <div className={styles.blurWrapper}>
                    <div aria-hidden='true' className={styles.imatgeBlurred}>
                        <Imatge imatges={imatge} tipus='web_quadrat' alt='' layout='fill' objectFit='cover' crop='200x200' />
                    </div>
                </div>
            )
        );
    };

    const _showIconaPlay = () => {
        if (tipologia === 'NOT_NOTICIA') return <></>;
        if (isWebviewActive && tipologianom === 'WCR_PROGRAMA') return <></>;
        if (isWebviewActive && tipologia === 'WCR_AUDIO_MM')
            return (
                <div className={styles.icona_play} data-testid={`slide-icona-reproduir`}>
                    <Icona icona='auriculars_ple' className={styles.play} alt='reproduir' mida={28} />
                </div>
            );

        return (
            isKeyframe &&
            tipus !== 'personatges' &&
            !isSeccio &&
            !isMidaLliure &&
            !isAudio && (
                <div className={styles.icona_play} data-testid={`slide-icona-reproduir`}>
                    <Icona icona='play' className={styles.play} alt='reproduir' isBlanc={true} mida={28} />
                </div>
            )
        );
    };
    const _showImatge = () => {
        return (
            <Imatge
                className={styles.imatge}
                imatges={imatge}
                tipus={midaImatge}
                alt={itemTitol}
                priority={false}
                mostrarRecursTitol={midaImatge !== 'web_keyframe'}
                layout='fill'
                objectFit='cover'
            />
        );
    };

    const _showLogoPrograma = () => {
        return (
            showLogoPrograma &&
            isKeyframe &&
            !isAudio && (
                <div className={styles.logoprograma} data-testid={`${dataTestId}-logoprograma`}>
                    <Imatge
                        imatges={imatgesPrograma}
                        tipus='web_logoprograma'
                        mostrarRecurs={false}
                        alt={itemTitol}
                        width={173}
                        height={93}
                    />
                </div>
            )
        );
    };

    const _showIconaCustom = () => {
        const iconaCustom =
            tipologia === 'WCR_PROGRAMA' || (tipologia === 'WCR_AUDIO_MM' && !isWebviewActive) ? 'auriculars_ple' : icona;

        return (
            !__isEmpty(iconaCustom) && (
                <span className={styleIcona} data-testid={`slide-icona-${iconaCustom}`}>
                    <Icona icona={iconaCustom} className={styles.innerOverIcon} alt={iconaCustom} />
                </span>
            )
        );
    };
    const _showEtiqueta = () => {
        return (
            !isRanking &&
            etiqueta &&
            etiqueta.length < 15 && (
                <div className={styles.etiqueta} data-testid={`slide-etiqueta`}>
                    {etiqueta}
                </div>
            )
        );
    };

    const _showTextWrapper = () => {
        const durada = _showDurada();
        const progress = _showProgressBar('in');

        return (
            <>
                {_showEntradeta()}
                {((codi_etic !== 'CE_TP' && !__isEmpty(codi_etic)) || !__isEmpty(durada) || !__isEmpty(progress)) && (
                    <div className={styles.extrainfo}>
                        {((codi_etic !== 'CE_TP' && !__isEmpty(codi_etic)) || !__isEmpty(durada)) && (
                            <div className={styles.codi_durada}>
                                {_showCodiEspectador()}
                                {durada}
                            </div>
                        )}
                        {progress}
                    </div>
                )}
            </>
        );
    };

    const _showImageWrapper = () => {
        return (
            <>
                {_showBlurImage()}
                {_showImatge()}
                {!_esticVeientPrograma() && _showLogoPrograma()}
                {_showIconaPlay()}
            </>
        );
    };

    const hoverHandler = (e) => {
        setIsHover(true);
        setHasBeenHovered(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    const _esticVeientPrograma = () => {
        if (tipus && tipus === 'ESTAS_VEIENT_PROGRAMA') {
            return true;
        }
        return false;
    };

    if (isFilter) {
        return (
            <div className={`${styles.wrapper} ${divHover}`}>
                <div className={styles.imatgeWrapper} onClick={() => handlerFilter(element)}>
                    {_showImageWrapper()}
                </div>
            </div>
        );
    }

    return (
        <div className={`${styles.wrapper} ${divHover}`} onMouseEnter={hoverHandler} onMouseLeave={outHandler}>
            {_showEtiqueta()}
            {isDirecteFutur ? (
                <div className={styles.textWrapper}>{_showTextWrapper()}</div>
            ) : (
                !isSeccio &&
                !isMidaLliure && (
                    <Enllac
                        marcatgeAudienciesClick={marcatgeAudienciesClick}
                        dataTestId={`slide-enllac-titol-${finalInfo.link}`}
                        route={_getRoute(isWebviewActive, isKeyframe, tipus, route, false)}
                        globalVar={mrecInfo ? { varName: 'mrec', value: mrecInfo } : ''}
                        title={itemTitol}
                        className={styles.textWrapper}
                        disponible={
                            tipus !== 'DESTACAT' ||
                            (tipus === 'DESTACAT' && !(finalInfo.url === '' || __isUndefined(finalInfo.url)))
                        }
                    >
                        {_showProgressBar('veient')}
                        {_showTextWrapper()}
                    </Enllac>
                )
            )}
            {isDirecteFutur ? (
                <div className={styles.imatgeWrapper}>{_showImageWrapper()}</div>
            ) : (
                <Enllac
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                    dataTestId={`slide-enllac-img-${finalInfo.link}`}
                    route={_getRoute(isWebviewActive, isKeyframe, tipus, route, true)}
                    globalVar={mrecInfo ? { varName: 'mrec', value: mrecInfo } : ''}
                    title={itemTitol}
                    className={styles.imatgeWrapper}
                    disponible={
                        tipus !== 'DESTACAT' || (tipus === 'DESTACAT' && !(finalInfo.url === '' || __isUndefined(finalInfo.url)))
                    }
                >
                    {estilModul === 'mobile_horitzontal' && _showProgressBar('sotaImatge')}
                    {_showImageWrapper()}
                </Enllac>
            )}

            {_showIconaCustom()}

            {!isDirecte &&
                !isSeccio &&
                !isMidaLliure &&
                tipus !== 'personatges' &&
                hasBeenHovered &&
                tipologia !== 'DTY_DESTACAT' && (
                    <AfegeixALaLlista
                        className={styles.afegeix}
                        id={tipologia === 'DTY_DESTACAT' ? finalInfo.idRelacionat : finalInfo.link}
                        tipologia={tipologia === 'DTY_DESTACAT' ? finalInfo.tipologiaRelacionada : tipologia}
                    />
                )}
        </div>
    );
}

Slide.defaultProps = {};
