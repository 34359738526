import { useRouter } from 'next/router';
import { createContext, useMemo, useState } from 'react';
import useGranter from 'hooks/useGranter';

export const ServicesContext = createContext({
    setServicesStatusInfo: (servicesStatusArray) => {},
    setServicesGrantsInfo: (servicesGrantsArray) => {},
    getServiceStatus: (serviceId) => {},
    getServiceGrant: (serviceId) => {}
});

let lastKnownServicesStatus = [];
let lastKnownServicesGrants = [];
let currentUserAgent = '';
let currentPathname = '';

const ServicesProvider = (props) => {
    const granter = useGranter();
    const router = useRouter();

    const publicApi = useMemo(() => {
        const isUserAgentMatched = (feature, list) => {
            let result = false,
                ua = currentUserAgent.toUpperCase(),
                candidates = feature[list];
            _.find(candidates, function (rule) {
                result = new RegExp(rule.toUpperCase()).test(ua);
                return result; //exit condition
            });
            return result;
        };
        const getDeviceGrant = (keyFeature) => {
            const allowList = lastKnownAllowListDeviceGrants[0];
            const feature = allowList?.[keyFeature];
            if (!feature) {
                return false;
            }
            const isWhitelisted = feature.whitelist ? isUserAgentMatched(feature, 'whitelist') : true;
            const isBlacklisted = feature.blacklist ? isUserAgentMatched(feature, 'blacklist') : false;
            return isWhitelisted && !isBlacklisted;
        };
        const setServicesStatusInfo = (servicesStatusInfo) => {
            lastKnownServicesStatus = servicesStatusInfo;
        };
        const setServicesGrantsInfo = (servicesGrantsInfo) => {
            lastKnownServicesGrants = servicesGrantsInfo;
            currentUserAgent = window.navigator.userAgent;
            currentPathname = router.pathname;
        };
        const getServiceStatus = (serviceId) => {
            const service = lastKnownServicesStatus.find((service) => service.name === serviceId);
            if (service?.status) {
                // Return the service status for 'serviceId' service
                const ok = service.status === 'ok';
                return [ok, { origin: 'main' }];
            }
            const fallback = lastKnownServicesStatus.find((service) => service.name === 'fallback');
            if (fallback?.status) {
                // Return the fallback service status
                const ok = fallback.status === 'ok';
                return [ok, { origin: 'fallback' }];
            }
            // Return the default service status
            return [true, { origin: 'default' }];
        };
        const getServiceGrant = (serviceId, verbose) => {
            const service = lastKnownServicesGrants.find((service) => service.id === serviceId);
            if (service) {
                granter.pushMainGrantInfo(
                    'userAgentGrant',
                    service?.userAgentGrant?.allowList,
                    service?.userAgentGrant?.blockList,
                    currentUserAgent
                );
                granter.pushMainGrantInfo(
                    'pathnameGrant',
                    service?.pathnameGrant?.allowList,
                    service?.pathnameGrant?.blockList,
                    currentPathname
                );
            }
            const fallback = lastKnownServicesGrants.find((service) => service.id === 'fallback');
            if (fallback) {
                granter.pushFallbackGrantInfo(
                    'userAgentGrant',
                    fallback?.userAgentGrant?.allowList,
                    fallback?.userAgentGrant?.blockList,
                    currentUserAgent
                );
                granter.pushFallbackGrantInfo(
                    'pathnameGrant',
                    fallback?.pathnameGrant?.allowList,
                    fallback?.pathnameGrant?.blockList,
                    currentPathname
                );
            }
            return granter.getCombinedGrant(verbose);
        };
        return {
            setServicesStatusInfo,
            setServicesGrantsInfo,
            getServiceStatus,
            getServiceGrant
        };
    }, []);

    return <ServicesContext.Provider value={publicApi} children={props.children} />;
};

export default ServicesProvider;
