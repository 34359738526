export const sizesPredef = {
    // web_background: '',
    web_mosaic:
        '(max-width: 569px) calc(100vw - 32px), (max-width: 767px) calc((100vw - 48px) / 2), (max-width: 926px) calc((100vw - 84px) / 2),' +
        '(max - width: 1210px) calc((100vw - 100px) / 3), (max - width: 1439px) calc((100vw - 116px) / 4),' +
        '(max - width: 1920px) calc((100vw - 160px) / 4), 468px',
    web_keyframe:
        '(max-width: 767px) calc((100vw - 36px) / 2), (max-width: 1023px) calc((100vw - 100px) / 3), (max-width: 1919px) calc((100vw - 160px) / 4),' +
        ' (max-width: 2144px) calc((100vw - 176px) / 5), (max-width: 2255px) 394px, 416px',
    web_keyframe_directe:
        '(max-width: 767px) calc((100vw - 36px) / 2), (max-width: 1023px) calc((100vw - 100px) / 3), (max-width: 1919px) calc((100vw - 160px) / 4),' +
        ' (max-width: 2144px) calc((100vw - 176px) / 5), (max-width: 2255px) 394px, 416px',
    // web_carrussel: '',
    web_destacat_horitzontal: '(max-width: 670px) calc(100vw - 32px), (max-width: 1920px) calc((100vw - 112px) / 3.7), 534px',
    web_logo: '(max-width: 1023px) 180px, (max-width: 1279px) 250px, 310px',
    web_posterpetit:
        '(max-width: 599px) calc((100vw - 48px) / 2.3), (max-width: 768px) calc((100vw - 72px) / 4.3), (max-width: 1023px) calc((100vw - 116px) / 4),' +
        ' (max-width: 1919px) calc((100vw - 192px) / 6), (max-width: 2144px) calc((100vw - 224px) / 8), (max-width: 2255px) 240px, 254px',
    web_postergran:
        '(max-width: 599px) calc((100vw - 48px) / 2.3), (max-width: 768px) calc((100vw - 72px) / 4.3), (max-width: 1023px) calc((100vw - 116px) / 4),' +
        ' (max-width: 1919px) calc((100vw - 160px) / 4), (max-width: 2144px) calc((100vw - 192px) / 5), (max-width: 2255px) 326px, 344px',
    web_ultimsdies: '(max-width: 767px) calc(100vw - 32px), (max-width: 948px) calc((100vw - 68px) / 2), 432px',
    web_quadrat:
        '(max-width: 399px) calc((100vw - 36px) / 2), (max-width: 599px) calc((100vw - 48px) / 3), (max-width: 767px) calc((100vw - 72px) / 4),' +
        ' (max-width: 1023px) calc((100vw - 116px) / 4), (max-width: 1919px) calc((100vw - 176px) / 5), (max-width: 2144px) calc((100vw - 224px) / 8),' +
        ' (max-width: 2255px) 240px, 254px',
    web_destacat: '(max-width: 2256px) 100vw, 2256px',
    web_superdestacat: '(max-width: 1919px) calc((100vw - 176px), 1440px',
    web_logoprograma: '(max-width: 1023px) 180px, (max-width: 1279px) 250px, 310px',
    web_carrussel: '(max-width: 1919px) calc((100vw - 176px), 1440px'
};

export const cropPredef = {
    //web_background: '',
    web_midalliure: '890x226',
    web_desfoto: '670x378',
    web_mosaic: '621x350',
    web_keyframe: '436x245',
    web_keyframe_directe: '436x245',
    web_carrussel: '1440x810',
    web_destacat_horitzontal: '670x378',
    web_logo: '310',
    web_posterpetit: '320x466',
    web_postergran: '320x466',
    web_ultimsdies: '432x244',
    web_quadrat: '200x200',
    web_destacat: '1903',
    web_xxss: '1920x1080',
    web_logoprograma: '312x180',
    web_autor: '64x64',
    hbbtv_background: '1280x720',
    //hbbtv_destacat: '',
    hbbtv_keyframe: '260x146',
    hbbtv_keyframe_directe: '260x146',
    hbbtv_superdestacat: '960x540',
    hbbtv_superdestacat_gran: '1090x613',
    hbbtv_logo: '310',
    hbbtv_posterpetit: '140x207',
    hbbtv_postergran: '260x380',
    hbbtv_quadrat: '200x200',
    smarttv_background: '1280x720',
    //smarttv_destacat: '',
    smarttv_keyframe: '260x146',
    smarttv_keyframe_directe: '260x146',
    smarttv_superdestacat: '960x540',
    smarttv_superdestacat_gran: '1090x613',
    smarttv_logo: '310',
    smarttv_posterpetit: '140x207',
    smarttv_postergran: '260x380',
    smarttv_quadrat: '200x200'
};
export const tipusPredef = {
    web_midalliure: [{ mida: 'MidaLliure' }, { mida: 'MASTER' }],
    web_desfoto: [{ mida: 'MASTER' }, { mida: '670x378' }, { mida: '326x184' }],
    web_background: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    web_destacat_horitzontal: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    web_ultimsdies: [
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' },
        { mida: '426x240' },
        { mida: '320x180' }
    ],
    web_destacat: [
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { mida: '1920x1080' },
        { mida: '1200x677' },
        { mida: '1024x576' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    web_keyframe: [
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '326x184' },
        { rel_name: 'KEYVIDEO', mida: '320x180' },
        { rel_name: 'KEYVIDEO', mida: '200x113' },
        { rel_name: 'KEYVIDEO', mida: '151x80' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '326x184' },
        { rel_name: 'keyvideo', mida: '320x180' },
        { rel_name: 'keyvideo', mida: '200x113' },
        { rel_name: 'keyvideo', mida: '151x80' },
        { mida: '670x378' }, // ARAFEM DIRECTES
        { mida: '326x184' }
    ],
    web_keyframe_directe: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    web_carrussel: [
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { mida: '1920x1080' },
        { mida: '1200x677' },
        { mida: '1024x576' },
        { mida: '1014x570' },
        { mida: '670x378' },
        { mida: '650x350' },
        { mida: '326x184' }
    ],
    web_logo: [
        { rel_name: 'img_logo', mida: 'MASTER' },
        { rel_name: 'IMG_LOGO', mida: 'MASTER' }
    ],
    web_logoprograma: [{ rel_name: 'IMAGE', mida: '312x180' }],
    web_posterpetit: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' },
        { rel_name: 'image', mida: 'MASTER' }
    ],
    web_postergran: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' }
    ],
    web_quadrat: [
        { rel_name: 'img_podcast', mida: 'MASTER' },
        { rel_name: 'IMG_PODCAST', mida: 'MASTER' },
        { mida: '300x300' },
        { mida: '200x200' }
    ],
    web_mosaic: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '326x184' },
        { rel_name: 'KEYVIDEO', mida: '320x180' },
        { rel_name: 'KEYVIDEO', mida: '200x113' },
        { rel_name: 'KEYVIDEO', mida: '151x80' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '326x184' },
        { rel_name: 'keyvideo', mida: '320x180' },
        { rel_name: 'keyvideo', mida: '200x113' },
        { rel_name: 'keyvideo', mida: '151x80' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    web_player_poster_background: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '426x240' },
        { rel_name: 'keyvideo', mida: '320x240' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '426x240' },
        { rel_name: 'KEYVIDEO', mida: '320x240' },
        { mida: 'MASTER' }
    ],
    web_xxss: [
        { rel_name: 'image', mida: '1014x570' },
        { rel_name: 'image', mida: '670x378' },
        { rel_name: 'image', mida: '426x240' },
        { rel_name: 'image', mida: '320x240' },
        { rel_name: 'image', mida: '428x218' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '426x240' },
        { rel_name: 'keyvideo', mida: '320x240' },
        { rel_name: 'imagexs', mida: 'XS' },
        { rel_name: 'IMAGE', mida: '1014x570' },
        { rel_name: 'IMAGE', mida: '670x378' },
        { rel_name: 'IMAGE', mida: '426x240' },
        { rel_name: 'IMAGE', mida: '320x240' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '426x240' },
        { rel_name: 'KEYVIDEO', mida: '320x240' },
        { rel_name: 'IMAGEXS', mida: 'XS' },
        { mida: '670x378' },
        { mida: '426x240' },
        { mida: '320x240' }
    ],
    web_autor: [{ mida: 'MASTER' }],

    hbbtv_keyframe: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '326x184' },
        { rel_name: 'KEYVIDEO', mida: '320x180' },
        { rel_name: 'KEYVIDEO', mida: '200x113' },
        { rel_name: 'KEYVIDEO', mida: '151x80' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '326x184' },
        { rel_name: 'keyvideo', mida: '320x180' },
        { rel_name: 'keyvideo', mida: '200x113' },
        { rel_name: 'keyvideo', mida: '151x80' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    hbbtv_keyframe_directe: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    hbbtv_background: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '670x378' }
    ],
    hbbtv_logo: [
        { rel_name: 'img_logo', mida: 'MASTER' },
        { rel_name: 'IMG_LOGO', mida: 'MASTER' }
    ],
    hbbtv_postergran: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' }
    ],
    hbbtv_posterpetit: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' }
    ],
    hbbtv_quadrat: [
        { rel_name: 'img_podcast', mida: 'MASTER' },
        { rel_name: 'IMG_PODCAST', mida: 'MASTER' },
        { mida: '300x300' },
        { mida: '200x200' },
        { mida: '144x144' }
    ],
    hbbtv_superdestacat: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '1920x1080' },
        { mida: '1200x677' },
        { mida: '1024x576' },
        { mida: '1014x570' },
        { mida: '670x378' },
        { mida: '650x350' },
        { mida: '326x184' }
    ],
    hbbtv_superdestacat_gran: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '1920x1080' },
        { mida: '670x378' }
    ],
    hbbtv_default: [{ mida: '670x378' }, { mida: '326x184' }],
    smarttv_keyframe: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'KEYVIDEO', mida: '326x184' },
        { rel_name: 'KEYVIDEO', mida: '320x180' },
        { rel_name: 'KEYVIDEO', mida: '200x113' },
        { rel_name: 'KEYVIDEO', mida: '151x80' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '326x184' },
        { rel_name: 'keyvideo', mida: '320x180' },
        { rel_name: 'keyvideo', mida: '200x113' },
        { rel_name: 'keyvideo', mida: '151x80' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    smarttv_keyframe_directe: [
        { rel_name: 'IMATGE_CUSTOM', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'imatge_custom', mida: 'DESTACAT_IMATGE' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMG_169_CT', mida: 'MASTER' },
        { rel_name: 'img_169_ct', mida: 'MASTER' },
        { mida: '670x378' },
        { mida: '326x184' }
    ],
    smarttv_background: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '670x378' }
    ],
    smarttv_logo: [
        { rel_name: 'img_logo', mida: 'MASTER' },
        { rel_name: 'IMG_LOGO', mida: 'MASTER' }
    ],
    smarttv_postergran: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' }
    ],
    smarttv_posterpetit: [
        { rel_name: 'img_poster', mida: 'MASTER' },
        { rel_name: 'IMG_POSTER', mida: 'MASTER' },
        { mida: '320x466' },
        { mida: '288x422' }
    ],
    smarttv_quadrat: [
        { rel_name: 'img_podcast', mida: 'MASTER' },
        { rel_name: 'IMG_PODCAST', mida: 'MASTER' },
        { mida: '300x300' },
        { mida: '200x200' },
        { mida: '144x144' }
    ],
    smarttv_superdestacat: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '1920x1080' },
        { mida: '1200x677' },
        { mida: '1024x576' },
        { mida: '1014x570' },
        { mida: '670x378' },
        { mida: '650x350' },
        { mida: '326x184' }
    ],
    smarttv_superdestacat_gran: [
        { rel_name: 'imatge_169', mida: 'MASTER' },
        { rel_name: 'IMATGE_169', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: 'MASTER' },
        { rel_name: 'keyvideo', mida: 'MASTER' },
        { rel_name: 'KEYVIDEO', mida: '1014x570' },
        { rel_name: 'keyvideo', mida: '1014x570' },
        { rel_name: 'KEYVIDEO', mida: '670x378' },
        { rel_name: 'keyvideo', mida: '670x378' },
        { mida: '1920x1080' },
        { mida: '670x378' }
    ],
    smarttv_default: [{ mida: '670x378' }, { mida: '326x184' }]
};
