import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import { useState } from 'react';
import styles from './pastilla.module.scss';

export default function Pastilla(props) {
    const { data, isActive } = props;
    const [isMouseDown, setIsMouseDown] = useState(false);

    const renderIntElements = ({ titol_pastilla, titol, icona, canal }) => {
        return (
            <>
                {icona && <LogoDirecte canal={canal} logo={icona} className={styles.logocanal} customText={titol} />}
                {titol_pastilla && !icona && <span className={styles.titol}>{titol_pastilla.toLowerCase()}</span>}
            </>
        );
    };

    const onMouseDown = () => {
        props.onSelect(data.id);
        setIsMouseDown(true);
    };

    const mouseDownStyle = () => {
        return isMouseDown && isActive ? styles.mouseHover : '';
    };

    const getDivClassName = ({ classname }) => {
        return `${styles.pastilla} ${styles[classname]}`;
    };

    return data ? (
        <div key={data.id} className={`${getDivClassName(data)} ${mouseDownStyle()}`} onMouseDown={onMouseDown}>
            {renderIntElements(data)}
        </div>
    ) : null;
}
