import { webVitalsLog } from 'components/Debug';
import { isNoticiesPath } from 'utils/pathHelper';
import 'styles/globals.scss';
//import 'styles/noticies/globals.scss';
import 'the-new-css-reset/css/reset.css';
import App3cat from './3cat/app3cat';
import App3catinfo from './3catinfo/app3catinfo';

function MyApp({ Component, pageProps, router }) {
    if (pageProps.errorCode) {
        throw Error(pageProps.errorCode);
    }

    // Evitem errors al prerender del build de la pipe
    if (router.pathname === '/3cat/app3cat' || router.pathname === '/3catinfo/app3catinfo') {
        return null;
    }

    const isNoticies = isNoticiesPath(router.asPath);

    return isNoticies ? (
        <App3catinfo Component={Component} pageProps={pageProps} />
    ) : (
        <App3cat Component={Component} pageProps={pageProps} />
    );
}
export default MyApp;

export function reportWebVitals(metric) {
    webVitalsLog(metric);
}