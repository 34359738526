import __reduce from 'lodash/reduce';

//import { isNoticiesPath } from 'utils/pathHelper';
import { trescatComponents } from './3cat/';
import { trescatinfoComponents } from './3catinfo/';

const COMPONENTSBYSITE = {
    '3catinfo': trescatinfoComponents,
    '3cat': trescatComponents
};

const AllComponents = trescatComponents;

const DynamicComponents = __reduce(
    AllComponents,
    (memo, component, key) => {
        memo[key] = component.dynamicComponent;
        return memo;
    },
    {}
);
const getDynamicComponents = (site) => {
    const siteComponents = COMPONENTSBYSITE[site];
    return __reduce(
        siteComponents,
        (memo, component, key) => {
            memo[key] = component.dynamicComponent;
            return memo;
        },
        {}
    );
};

export { AllComponents, DynamicComponents, getDynamicComponents };
