import Boto from '../Boto/Boto';
import styles from './notfound.module.scss';

export default function NotFound(props) {
    const { code, text, cleanUrlParams } = props;

    return (
        <div className={styles.notfound} data-testid={`error-${code}`}>
            <p className={styles.codi}>{code}</p>
            <h3 className={styles.titol}>{text}</h3>
            <p className={styles.text}>Visita la nostra portada i explora les notícies que més interessen</p>
            <Boto
                tag='a'
                text='Ves a la portada'
                className={styles.boto}
                route={{ href: '/3catinfo' }}
                cleanUrlParams={cleanUrlParams}
            />
        </div>
    );
}

NotFound.defaultProps = {
    text: '',
    cleanUrlParams: false
};
