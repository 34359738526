import React from 'react';
import Head from 'next/head';

const Metadades = () => {
  return (
    <Head>
      <meta name="robots" content="noindex"></meta>
    </Head>
  );
};

export default Metadades;
