import { UserContext } from 'context/UserProvider';
import { useContext } from 'react';

export const useUser = () => {
    const {
        accessToken,
        name,
        inicial,
        id,
        email,
        username,
        surname,
        isAuthenticated,
        isWebView,
        state,
        uuid,
        deviceUid,
        deviceType,
        signUp,
        signIn,
        signOut,
        editProfileCP,
        changePassCP,
        changeEmail,
        deleteMyAccount
    } = useContext(UserContext);

    return {
        accessToken,
        name,
        inicial,
        id,
        email,
        username,
        surname,
        isAuthenticated,
        isWebView,
        state,
        uuid,
        deviceUid,
        deviceType,
        signUp,
        signIn,
        signOut,
        editProfileCP,
        changePassCP,
        changeEmail,
        deleteMyAccount
    };
};
