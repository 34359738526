export const itemsMenuEstatic = {
    item: [
        {
            type: 'web_3cat_seccio',
            text: 'directes',
            id: 'directes',
            icona: 'directes',
            icona_alt: 'Icona directes',
            tag_friendly: 'directes'
        },
        {
            type: 'web_3cat_seccio',
            text: 'entreteniment',
            id: 'entreteniment',
            tag_friendly: 'entreteniment'
        },
        {
            type: 'web_3cat_seccio',
            text: 'sèries',
            id: 'series',
            tag_friendly: 'series'
        },
        {
            type: 'web_3cat_seccio',
            text: 'pòdcasts',
            id: 'podcasts',
            tag_friendly: 'podcasts'
        },
        {
            type: 'web_3cat_seccio',
            text: 'el més top',
            id: 'mes-top',
            tag_friendly: 'mes-top'
        },
        {
            type: 'web_3cat_seccio',
            text: 'la meva llista',
            id: 'meva-llista',
            tag_friendly: 'meva-llista'
        },
        {
            type: 'web_3cat_seccio',
            text: 'explora <span class="sr-only">més seccions</span>',
            id: '',
            icona: '',
            icona_alt: '',
            opcions: [
                {
                    type: 'web_3cat_seccio',
                    text: 'actualitat',
                    id: 'actualitat',
                    tag_friendly: 'actualitat'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'esports',
                    id: 'esports',
                    tag_friendly: 'esports'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'cultura i divulgació',
                    id: 'cultura',
                    tag_friendly: 'cultura'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'pel·lícules',
                    id: 'pellicules',
                    tag_friendly: 'pellicules'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'documentals i reportatges',
                    id: 'documentals-i-reportatges',
                    tag_friendly: 'documentals'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'cuines',
                    id: 'cuines',
                    tag_friendly: 'cuines'
                },

                {
                    type: 'web_3cat_seccio',
                    text: 'SX3',
                    id: 'sx3',
                    tag_friendly: 'sx3'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'supercampus',
                    id: 'supercampus',
                    tag_friendly: 'supercampus'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'EVA',
                    id: 'eva',
                    tag_friendly: 'eva'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'col·leccions',
                    id: 'colleccions',
                    tag_friendly: 'colleccions'
                },
                {
                    type: 'web_3cat_ultimsdies_canal',
                    text: 'últims 10 dies',
                    id: 'tv3',
                    tag_friendly: 'ultims-dies'
                },
                {
                    type: 'web_3cat_seccio',
                    text: 'continguts accessibles',
                    id: 'accessibilitat',
                    tag_friendly: 'accessibilitat'
                },
                {
                    type: 'web_3cat_seccio',
                    text: "continguts d'Aran",
                    id: 'aran',
                    tag_friendly: 'aran'
                },
                {
                    type: 'linkArxiuAZ',
                    text: 'tot el catàleg',
                    id: 'tot',
                    tag_friendly: 'tot-cataleg'
                }
            ]
        }
    ]
};
