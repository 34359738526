import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { useDelivertyUser } from 'hooks/useDelivertyUser';
import styles from './logindty.module.scss';

export default function LoginDty(props) {
    const { error, missatge } = props;
    const logoImg = '/_resources/logos/dty-logo.svg';

    const { loginUser } = useDelivertyUser();

    const [empty, setEmpty] = useState(true);
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
    });

    const changeState = (e) => {
        e.preventDefault();
        if (isEmpty(e.target?.value)) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    };
    const setActive = (e) => {
        e.preventDefault();
        setEmpty(false);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value })); // update state based on form data
    };

    const className = clsx({
        [styles.rLoginWrapper]: true,
        'shadow': true,
        'd-flex': true
    });
    const labelClassName = clsx(styles.rCampLabel, {
        [styles.empty]: empty,
    });
    const rCampClassName = clsx(styles.rCamp, styles.inputCamp, {
        [styles.rCampEmpty]: !empty,
        [styles.valueError]: !isEmpty(error),
    });

    const renderMissatge = useCallback(() => {
        const errorClassName = clsx(styles.rLoginError, {
            'login-error fs-5': true,
            'login-error-test': true
        });
        const nameClassName = clsx(styles.rName);

        return missatge ? <div className={errorClassName}>{missatge}</div> : <div className={nameClassName} data-testid='login-deliverty'>Login deliverty</div>;
    }, [missatge]);

    const handleSubmit = (e) => {
        e.preventDefault();

        /** apply validation logic to formData before login */

        loginUser({
            user: formData?.userName,
            clau: formData?.password
        });
    };

    return (
        <div className={styles.mask}>
            <div className={className}>
                <img className={styles.imDtyLogo} src={logoImg} width='66' height='69' alt='Logotip de Deliverty' />
                {renderMissatge()}
                <form onSubmit={handleSubmit} className={styles.rFormLogin}>
                    <div className={rCampClassName}>
                        <label htmlFor='userName' className={labelClassName}>
                            Usuari
                        </label>
                        <input
                            type='text'
                            id='usuari'
                            data-testid='usuari-deliverty'
                            name='userName'
                            className={styles.rCampInput}
                            onBlur={changeState}
                            onFocus={setActive}
                            onChange={handleInputChange}
                            aria-label='Usuari'
                            aria-required='true'
                        />
                    </div>
                    <div className={rCampClassName}>
                        <label htmlFor='password' className={labelClassName}>
                            Password
                        </label>
                        <input
                            type='password'
                            id='password'
                            data-testid='password-deliverty'
                            name='password'
                            className={styles.rCampInput}
                            onBlur={changeState}
                            onFocus={setActive}
                            onChange={handleInputChange}
                            aria-label='Password'
                            aria-required='true'
                        />
                    </div>
                    <div className={styles.marginAuto}>
                        <button className={styles.rCampButton} type='submit' data-testid='submit-deliverty'>
                            Accedeix
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
