import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import styles from './slider.module.scss';

const addSlideClass = (e, swiper) => {
    const slide = e.currentTarget;
    let currentTargetRect = slide.getBoundingClientRect();

    if (currentTargetRect.left - swiper.current.getBoundingClientRect().left < 80) {
        return 'openRight';
    } else if (swiper.current.offsetWidth - (currentTargetRect.left + currentTargetRect.width) < 80) {
        return 'openLeft';
    } else {
        return '';
    }
};
const calculaUrlBotoMes = (tipus, url, firstItem) => {
    let route = false;
    /*
    Casos:
    https://www.3cat.cat/3cat/mes-top/
    https://www.3cat.cat/3cat/meva-llista/
    */
    switch (tipus) {
        case 'DIRECTES': //https://www.3cat.cat/3cat/directes/
            route = {
                type: 'web_3cat_directe',
                id: 'directes'
            };
            break;
        case 'VIDEOS':
            const urlParsed = !__isUndefined(url) ? new URL(url) : null;
            const params = urlParsed?.searchParams;
            if (params?.get('programatv_id')) {
                // PROGRAMA https://www.3cat.cat/3cat/PROGRAMA/
                const slug = firstItem?.programes_tv?.[0].nom_bonic;
                if (slug) {
                    route = {
                        tipologia: 'PTVC_PROGRAMA',
                        id: params.get('programatv_id'),
                        slug: slug
                    };
                }
            } else if (params?.get('frontal')?.includes('dty_colleccio')) {
                // COLECCIONS https://www.3cat.cat/3cat/coleccio/17270/
                const frontal = params.get('frontal')?.split('dty_colleccio'); //frontal=pucollecio-pucolvid-dty_colleccio17270
                route = {
                    id: frontal?.[1],
                    type: 'web_3cat_coleccio_item'
                };
            }
            break;
        case 'MEVA_LLISTA': //https://www.3cat.cat/apptv/hbbtv/3cat/meva-llista/
            route = {
                type: 'web_3cat_seccio',
                id: 'meva-llista'
            };
            break;
        case 'PROGRAMES':
            break;
        case 'COLLECCIO':
            break;
        case 'PUBLICITAT':
            break;
        case 'ESTAS_VEIENT':
            break;
        case 'RECOMANEM':
            break;
        case 'RANQUING': //https://www.3cat.cat/apptv/hbbtv/3cat/mes-top/
            break;
        case 'PROGRAMES_RADIO': // https://www.3cat.cat/apptv/hbbtv/3cat/programa-radio/1909/
            break;
        case 'DIRECTES_FUTUR':
            break;
        case 'TEMATIQUES':
            break;
    }
    return route;
};

const getInfoTitol = ({ titol, iconaTitol, iconaTitolAlt, route, sliderId }) => {
    if (!__isEmpty(route) && !__isEmpty(titol)) {
        return (
            <h2 className={styles.infoTitol} id={sliderId}>
                <Enllac className={styles.infoLink} route={route}>
                    {!__isEmpty(iconaTitol) && (
                        <Icona
                            icona={iconaTitol}
                            alt={iconaTitolAlt}
                            isBlanc={true}
                            mida={18}
                            className={styles.linktitol}
                            ariaHidden={true}
                        />
                    )}{' '}
                    {titol}
                </Enllac>
                <Icona
                    icona='fletxa_simple_dreta'
                    alt='Següent'
                    isBlanc={true}
                    mida={18}
                    className={styles.linktitol}
                    ariaHidden={true}
                />
            </h2>
        );
    }

    if (!__isEmpty(titol)) {
        return (
            <h2 className={styles.infoTitol} id={sliderId}>
                {!__isEmpty(iconaTitol) && (
                    <Icona
                        icona={iconaTitol}
                        alt={iconaTitolAlt}
                        isBlanc={true}
                        mida={18}
                        className={styles.linktitol}
                        ariaHidden={true}
                    />
                )}{' '}
                {titol}
            </h2>
        );
    }
};

const getVisibleSlides = (propsSwiper) => {
    const breakpoints = Object.entries(propsSwiper.breakpoints);

    const breakPointIndex = breakpoints.reverse().findIndex((element) => element[0] < window.innerWidth);

    const visibleSlides =
        breakPointIndex < 0
            ? Math.floor(propsSwiper.slidesPerView) * 2
            : Math.floor(propsSwiper.breakpoints[breakpoints[breakPointIndex][0]].slidesPerView) * 2;

    return visibleSlides;
};

const getPropsSwiper = (format) => {
    let breakpoints = {
        600: {
            slidesPerView: 2,
            spaceBetween: 12,
            slidesPerGroup: 2
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 16,
            slidesPerGroup: 3
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 16,
            slidesPerGroup: 4
        },
        1920: {
            slidesPerView: 5,
            spaceBetween: 16,
            slidesPerGroup: 5
        }
    };
    if (format === 'destacat_2_items') {
        breakpoints = {
            300: {
                slidesPerView: 1.5,
                spaceBetween: 12,
                slidesPerGroup: 1
            },
            600: {
                slidesPerView: 2,
                spaceBetween: 12,
                slidesPerGroup: 2
            }
        };
    }
    if (format === 'mobile_horitzontal') {
        breakpoints = {
            100: {
                slidesPerView: 1,
                spaceBetween: 12,
                slidesPerGroup: 1
            },
            530: {
                slidesPerView: 2,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 16,
                slidesPerGroup: 3
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1920: {
                slidesPerView: 5,
                spaceBetween: 16,
                slidesPerGroup: 5
            }
        };
    }
    if (format === 'wrappedKeyframe') {
        breakpoints = {
            600: {
                slidesPerView: 2.5,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            768: {
                slidesPerView: 3.5,
                spaceBetween: 16,
                slidesPerGroup: 3
            },
            1024: {
                slidesPerView: 4.5,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1920: {
                slidesPerView: 6.5,
                spaceBetween: 16,
                slidesPerGroup: 6
            }
        };
    }
    if (format === 'web_quadrat') {
        breakpoints = {
            0: {
                slidesPerView: 2.1
            },
            320: {
                slidesPerView: 2.3,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            600: {
                slidesPerView: 3.3,
                spaceBetween: 12,
                slidesPerGroup: 4
            },
            768: {
                slidesPerView: 4.3,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            },
            1440: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            },
            1920: {
                slidesPerView: 8,
                spaceBetween: 16,
                slidesPerGroup: 8
            }
        };
    }
    if (format === 'web_postergran') {
        breakpoints = {
            0: {
                slidesPerView: 2.1
            },
            320: {
                slidesPerView: 2.3,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            600: {
                slidesPerView: 3.3,
                spaceBetween: 12,
                slidesPerGroup: 4
            },
            768: {
                slidesPerView: 4.3,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1920: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            }
        };
    }
    if (format === 'web_posterpetit') {
        breakpoints = {
            0: {
                slidesPerView: 2.1
            },
            320: {
                slidesPerView: 2.3,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            600: {
                slidesPerView: 3.3,
                spaceBetween: 12,
                slidesPerGroup: 4
            },
            768: {
                slidesPerView: 4.3,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            },
            1440: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            },
            1920: {
                slidesPerView: 8,
                spaceBetween: 16,
                slidesPerGroup: 8
            }
        };
    }
    if (format === 'seccions') {
        breakpoints = {
            320: {
                slidesPerView: 1.7,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            600: {
                slidesPerView: 2.5,
                spaceBetween: 12,
                slidesPerGroup: 2
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 16,
                slidesPerGroup: 3
            },
            1024: {
                slidesPerView: 3.5,
                spaceBetween: 16,
                slidesPerGroup: 3
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 16,
                slidesPerGroup: 4
            },
            1920: {
                slidesPerView: 6,
                spaceBetween: 16,
                slidesPerGroup: 6
            }
        };
    }
    if (format === 'PASTILLA') {
        breakpoints = {
            320: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                slidesPerGroup: 2
            },
            600: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                slidesPerGroup: 4
            },
            768: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                slidesPerGroup: 6
            },
            1024: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                slidesPerGroup: 7
            },
            1440: {
                slidesPerView: 'auto',
                spaceBetween: 30
            },
            1920: {
                slidesPerView: 'auto',
                spaceBetween: 35
            },
            2256: {
                slidesPerView: 'auto',
                spaceBetween: 42
            }
        };
    }

    return {
        spaceBetween: 12,
        slidesPerView: 1.3,
        slidesPerGroup: 1,
        freeMode: true,
        watchSlidesProgress: true,
        simulateTouch: false,
        breakpoints: breakpoints
    };
};

export { addSlideClass, calculaUrlBotoMes, getInfoTitol, getPropsSwiper, getVisibleSlides };
