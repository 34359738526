import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import __isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './menu.module.scss';

const _buildHomeEnllac = (props) => {
    const { seccio, titol, slug, mostraH1 } = props;
    const stylesLink = clsx({
        [styles.link]: true,
        [styles.active]: 'home' === seccio
    });

    const Etiqueta = mostraH1 === true ? `${'h1'}` : `${'div'}`;

    return titol !== '' && slug !== '' ? (
        <SwiperSlide
            key={`menuDinamic-${slug}-titol`}
            tag='li'
            className={styles.menuItem + ' ' + styles.menuTitle}
            data-testid={`menuDinamic-${slug}-titol`}
        >
            <Enllac className={stylesLink} type='itemProgramaHome' slug={slug}>
                {seccio == 'home' && <Etiqueta className={styles.titolMenu}>{titol}</Etiqueta>}
                {seccio != 'home' && titol}
            </Enllac>
        </SwiperSlide>
    ) : null;
};

const _buildMediaEnllac = (props) => {
    const { items, seccio, titol, slug, medias, urlApi } = props;
    const isProgramaRadio = urlApi.includes('/programesradio/');
    const stylesLinkCapitols = clsx({
        [styles.link]: true,
        [styles.active]: 'capitols' === seccio || 'video' === seccio
    });

    const stylesLinkAudios = clsx({
        [styles.link]: true,
        [styles.active]: 'Últims programes' === seccio || 'audio' === seccio
    });

    const mediaLinks = [];
    if (titol !== '' && slug !== '' && __isEmpty(items) && medias) {
        if (medias.audioPub === 'SI' && medias.audios) {
            mediaLinks.push(
                <SwiperSlide
                    key={`menuDinamic-${slug}-ultims-programes`}
                    tag='li'
                    className={styles.menuItem}
                    data-testid={`menuDinamic-${slug}-ultims-programes`}
                >
                    <Enllac
                        className={stylesLinkAudios}
                        type='web_3cat_programa_seccio'
                        slug={slug}
                        slugRelacio='ultims-programes'
                    >
                        programes
                    </Enllac>
                </SwiperSlide>
            );
        }

        if (medias.capitols) {
            if (isProgramaRadio) {
                mediaLinks.push(
                    <SwiperSlide
                        key={`menuDinamic-${slug}-videos`}
                        tag='li'
                        className={styles.menuItem}
                        data-testid={`menuDinamic-${slug}-videos`}
                    >
                        <Enllac className={stylesLinkCapitols} type='web_3cat_programa_seccio' slug={slug} slugRelacio='videos'>
                            vídeos
                        </Enllac>
                    </SwiperSlide>
                );
            } else {
                mediaLinks.push(
                    <SwiperSlide
                        key={`menuDinamic-${slug}-capitols`}
                        tag='li'
                        className={styles.menuItem}
                        data-testid={`menuDinamic-${slug}-capitols`}
                    >
                        <Enllac className={stylesLinkCapitols} type='web_3cat_programa_seccio' slug={slug} slugRelacio='capitols'>
                            capítols
                        </Enllac>
                    </SwiperSlide>
                );
            }
        }
    }

    return mediaLinks;
};

const _buildMesInfo = (props) => {
    const { seccio, titol, slug, mesInfo } = props;
    const stylesLink = clsx({
        [styles.link]: true,
        [styles.active]: 'mesinfo' === seccio
    });

    return titol !== '' && slug !== '' && mesInfo ? (
        <SwiperSlide
            key={`menuDinamic-${slug}-mes-info`}
            tag='li'
            className={styles.menuItem}
            data-testid={`menuDinamic-${slug}-mes-info`}
        >
            <Enllac className={stylesLink} type='web_3cat_programa_seccio' slug={slug} slugRelacio='mes-info'>
                més info
            </Enllac>
        </SwiperSlide>
    ) : (
        []
    );
};

const _buildEnllac = (seccio, element, i, slug, type, mostraH1) => {
    const { id, link, tipologia, domini, tag_friendly, nomFriendly, nom, desc, url } = element;

    const stylesLink = clsx({
        [styles.link]: true,
        [styles.active]:
            desc === seccio ||
            tag_friendly === seccio ||
            parseInt(id) === seccio ||
            (seccio === 'video' && tag_friendly === 'capitols') ||
            (seccio === 'clip' && tag_friendly === 'clips')
    });

    const Etiqueta = mostraH1 === true ? `${'h1'}` : `${'div'}`;
    let href = null;

    if (tag_friendly?.includes('/')) {
        href = tag_friendly;
    } else if (url) href = url;

    const route = {
        href: href,
        type: 'web_3cat_programa_seccio',
        id: link,
        tipologia: tipologia,
        domini: domini || 'TVC',
        slug: slug,
        slugRelacio: tag_friendly || nomFriendly,
        tipologiaRelacionada: nom
    };

    return (
        <SwiperSlide
            key={`menuDinamic-${slug}-${id}`}
            tag='li'
            className={styles.menuItem}
            data-testid={`menuDinamic-${slug}-${desc}`}
        >
            <Enllac className={stylesLink} route={route}>
                {seccio == element.desc && <Etiqueta className={styles.titolMenu}>{desc.toLowerCase()}</Etiqueta>}
                {seccio != element.desc && element.desc.toLowerCase()}
            </Enllac>
        </SwiperSlide>
    );
};

const MenuDinamic = (props) => {
    const { items, seccio, slug, type, mostraH1 } = props;
    const [submenuPosition, setSubmenuPosition] = useState('left');
    let resizeTimeOut;

    const stylesSwiper = clsx({
        [styles.menuItems]: true,
        [styles.dreta]: submenuPosition == 'left',
        [styles.esquerra]: submenuPosition == 'right',
        [styles.centre]: submenuPosition == 'center'
    });

    const _iterateItems = (items) => {
        const dinamicLinks = items
            .sort((a, b) => (parseInt(a.ordre) > parseInt(b.ordre) ? 1 : -1))
            .map((element, i) => _buildEnllac(seccio, element, i, slug, type, mostraH1));
        const homeLink = _buildHomeEnllac(props);
        const mediaLink = _buildMediaEnllac(props);
        const mesInfoLink = _buildMesInfo(props);
        return [homeLink].concat(dinamicLinks).concat(mediaLink).concat(mesInfoLink);
    };

    return (
        <>
            <Swiper
                modules={[FreeMode]}
                className={stylesSwiper}
                slidesPerView={'auto'}
                freeMode={true}
                tag='nav'
                wrapperTag='ul'
                onProgress={(swiper) => {
                    if (swiper.progress <= 0) {
                        setSubmenuPosition('left');
                    } else if (swiper.progress >= 1) {
                        setSubmenuPosition('right');
                    } else {
                        setSubmenuPosition('center');
                    }
                }}
                breakpoints={{
                    1279: {
                        spaceBetween: 24
                    }
                }}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            swiper.update();
                        }, 100);
                    });
                }}
            >
                {_iterateItems(items)}
            </Swiper>
        </>
    );
};
MenuDinamic.defaultProps = {
    mostraH1: false
};

export default MenuDinamic;
