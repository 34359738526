import { getStorageValue, setStorageValue } from '@portal-internet/core';
import { getData } from '@portal-internet/bff';
import { useUser } from 'hooks/useUser';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import { createContext, useEffect, useState } from 'react';

const expiresInMinutes = 5;
const minutesPerDay = 1440;
const expires = expiresInMinutes / minutesPerDay;

const urlBase = 'https://api.3cat.cat/usuaris/llistes';
export const ContentUserContext = createContext();

const ContentUserProvider = (props) => {
    const { children } = props;
    const { isAuthenticated, accessToken, uuid } = useUser();
    const [itemsLaMevaLlista, setItemsLaMevaLlista] = useState({});
    const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            obtenirItemsLaMevaLlista();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken]);

    const isStorageAvailable = () => {
        return !!(typeof window !== 'undefined' && window?.CCMAStorage);
    };
    const shouldProcess = () => {
        return isAuthenticated && isStorageAvailable();
    };
    const shouldFetch = () => {
        //toca mirar ccmastorage o api?
        const itemsNotLoaded = __isEmpty(itemsLaMevaLlista);
        const itemsExpired = Date.now() - lastFetchedTimestamp > expiresInMinutes * 60000;
        return itemsExpired || itemsNotLoaded;
    };

    const getItemsFromCCMAStorage = async () => {
        return await getStorageValue(['_ccma_lamevallista_', uuid].join(''));
    };

    const obtenirItemsLaMevaLlista = async () => {
        if (shouldProcess() && shouldFetch()) {
            let items = {};
            const plainData = await getItemsFromCCMAStorage();
            try {
                items = JSON.parse(plainData);
            } catch (e) {
                items = {};
            }
            if (__isEmpty(items)) {
                items = await getItemsFromApi();
            }

            setItemsLaMevaLlista(items);
            setLoaded(true);
            setLastFetchedTimestamp(Date.now());
        }
    };
    const isStatusOk = (rawData) => {
        return __get(rawData, 'resposta.status') === 'OK';
    };

    const removeItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/esborrarcontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-remove', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };
    const addItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/afegircontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-add', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };

    const getItemsFromApi = async () => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/obtenircontinguts?_format=json&tipus_llista=permestard&tipologia=DTY_VIDEO_MM,WCR_AUDIO_MM,WCR_PROGRAMA,PTVC_PROGRAMA&cache=30&vista=HISTORIC&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista', { url, fetchFromOrigin: true }] });
        let items = [];

        if (isStatusOk(rawData)) {
            items = rawData.resposta?.items?.item?.reduce((list, item) => {
                if (!list[item.tipologia]) {
                    list[item.tipologia] = [];
                }
                list[item.tipologia].push(Number(item.contingut_id));
                return list;
            }, {});
            setItemsToCCMAStorage(items);
        }
        return items;
    };

    const setItemsToCCMAStorage = (items) => {
        const plainData = JSON.stringify(items);
        setStorageValue(['_ccma_lamevallista_', uuid].join(''), plainData, {
            path: '/',
            domain: '.3cat.cat',
            expires: expires
        });
    };

    const isALaMevaLlista = (idint, tipologia) => {
        return itemsLaMevaLlista?.[tipologia]?.includes(Number(idint));
    };

    const eliminaALaMevaLlista = (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        const items = itemsLaMevaLlista;
        if (items[tipologia]) {
            const index = items[tipologia].indexOf(Number(idint));
            if (index > -1) {
                items[tipologia].splice(index, 1);
                updateItemsLaMevaLlista(items);
                isStatusOk = removeItemToAPI(idint, tipologia);
            }
        }
        setIsLoading(false);
        return isStatusOk;
    };
    const afegeixALaMevaLlista = (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        let items = itemsLaMevaLlista;
        if (!items[tipologia]) {
            items[tipologia] = [];
        }
        if (!isALaMevaLlista(idint, tipologia)) {
            items[tipologia].push(Number(idint));
            updateItemsLaMevaLlista(items);
            isStatusOk = addItemToAPI(idint, tipologia);
        }
        setIsLoading(false);
        return isStatusOk;
    };
    const updateItemsLaMevaLlista = (items) => {
        setItemsToCCMAStorage(items);
        setItemsLaMevaLlista(items);
    };

    return (
        <ContentUserContext.Provider value={{ isALaMevaLlista, eliminaALaMevaLlista, afegeixALaMevaLlista, isLoading, loaded }}>
            {children}
        </ContentUserContext.Provider>
    );
};

export default ContentUserProvider;
