import { getDataInfoVideo } from '@portal-internet/core';
import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import Icona from 'components/3cat/ui/Icona/Icona';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import Info from './Info';
import styles from './quadrat.module.scss';

const _showIcona = () => {
    return (
        <span className={styles.overIcon}>
            <Icona icona='auriculars_ple' className={styles.innerOverIcon} alt='icona auriculars' />
        </span>
    );
};

const _showBlurImage = (props) => {
    const { data, mida } = props;

    return (
        <div className={styles.blurWrapper}>
            <div aria-hidden='true' className={styles.imatgeBlurred}>
                <Imatge imatges={data.imatges} tipus={mida} alt='' layout='fill' objectFit='cover' crop='200x200' />
            </div>
        </div>
    );
};

const _showImatge = (props) => {
    const { data, mida } = props;
    return (
        <Imatge className={styles.imatge} imatges={data.imatges} tipus={mida} alt={data.titol} layout='fill' objectFit='cover' />
    );
};

const Quadrat = (props) => {
    const {
        data,
        hover,
        style,
        ranked,
        rankedMobile,
        marcatgeAudienciesClick,
        mosaicPosition,
        nomPrograma,
        showAfegeixALaLlista,
        mostraCodiEtic,
        routeType,
        slugRelacio
    } = props;

    const finalInfo = getDataInfoVideo(data);
    const {
        nombonic,
        link,
        tipologia,
        canals,
        domini,
        titol,
        tipologianom,
        url,
        idRelacionat,
        tipologiaRelacionada,
        permatitle
    } = finalInfo;

    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');

    const route = {
        type: routeType,
        id: link,
        tipologia: tipologia,
        canals: canals,
        domini: domini || 'TVC',
        slug: nombonic || permatitle || titol,
        slugPrograma: nomPrograma || finalInfo.nomprograma,
        tipologiaRelacionada: tipologianom,
        href: tipologia !== 'WCR_PROGRAMA' ? url : null,
        slugRelacio: slugRelacio
    };

    const styleMosaic = clsx({
        [styles.mosaic]: !!mosaicPosition,
        [styles.hasHover]: !!hover,
        [styles.quadrat]: true,
        [styles.ranking]: !__isEmpty(ranked),
        [styles.rankingMobile]: !__isEmpty(rankedMobile)
    });

    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.wrapperHover]: true }));
            }, 200);
        }
    }, [isHover]);

    const hoverHandler = (e) => {
        setIsHover(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    const _showImageWrapper = () => {
        return (
            <>
                {' '}
                {_showBlurImage(props)}
                {_showImatge(props)}
            </>
        );
    };

    let afegeixALaLlistaId = link;
    let afegeixALaLlistaTipologia = tipologia;
    if (tipologia === 'DTY_DESTACAT') {
        afegeixALaLlistaId = idRelacionat;
        afegeixALaLlistaTipologia = tipologiaRelacionada;
    }

    return data ? (
        <div
            key={data.id}
            style={{ ...style }}
            className={`${styleMosaic}`}
            onMouseEnter={hoverHandler}
            onMouseLeave={outHandler}
        >
            <div className={`${styles.wrapper} ${divHover}`}>
                <Info data={finalInfo} route={route} mostraCodiEtic={mostraCodiEtic} />

                <Enllac route={route} className={styles.imatgeWrapper} marcatgeAudienciesClick={marcatgeAudienciesClick}>
                    {_showImageWrapper()}
                </Enllac>

                {_showIcona()}

                {showAfegeixALaLlista && tipologia !== 'DTY_DESTACAT' && (
                    <AfegeixALaLlista className={styles.afegeix} id={afegeixALaLlistaId} tipologia={afegeixALaLlistaTipologia} />
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};

Quadrat.defaultProps = {
    showAfegeixALaLlista: true,
    mida: 'web_quadrat',
    mostraCodiEtic: true,
    routeType: '',
    slugRelacio: ''
};

export default Quadrat;
