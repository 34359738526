const qaOberturaDefaultInvertit = [
    {
        name: 'BlocREDL',
        finalProps: {
            name: 'Obertura diaria'
        },
        children: [
            {
                name: 'Fila',
                finalProps: {
                    columnes: '6_6',
                    tipus: 'fixed'
                },

                children: [
                    {
                        name: 'Columna',
                        children: [
                            {
                                name: 'Fila',
                                finalProps: {
                                    columnes: '6_6',
                                    tipus: 'fixed'
                                },
                                children: [
                                    {
                                        name: 'Destacat',
                                        finalProps: {
                                            isLoading: true,
                                            tipologia: 'NOT_NOTICIA'
                                        }
                                    },
                                    {
                                        name: 'Destacat',
                                        finalProps: {
                                            isLoading: true,
                                            tipologia: 'NOT_NOTICIA'
                                        }
                                    }
                                ]
                            },

                            {
                                name: 'Fila',
                                finalProps: {
                                    columnes: '4_4_4',
                                    tipus: 'fixed'
                                },
                                children: [
                                    {
                                        name: 'Destacat',
                                        finalProps: {
                                            isLoading: true,
                                            tipologia: 'NOT_NOTICIA'
                                        }
                                    },
                                    {
                                        name: 'Destacat',
                                        finalProps: {
                                            isLoading: true,
                                            tipologia: 'NOT_NOTICIA'
                                        }
                                    },
                                    {
                                        name: 'Destacat',
                                        finalProps: {
                                            isLoading: true,
                                            tipologia: 'NOT_NOTICIA'
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: 'Destacat',
                        finalProps: {
                            isLoading: true,
                            tipologia: 'NOT_NOTICIA'
                        }
                    }
                ]
            }
        ]
    }
];

export default qaOberturaDefaultInvertit;
