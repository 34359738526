import { createContext, useEffect, useState } from 'react';
import { getStorageValue } from '@portal-internet/core';
export const ModalContext = createContext();

const defaultOptions = {
    showControls: true,
    mode: 'full-screen'
};

const ModalProvider = (props) => {
    const { children } = props;

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [optionsModal, setOptionsModal] = useState(defaultOptions);
    const [mutePlayer, setMutePlayer] = useState(false);

    const setModal = (content, options = defaultOptions) => {
        options = { ...defaultOptions, ...options };
        setOpenModal(true);
        setOptionsModal(options);
        setModalContent(content);

        if (typeof document !== 'undefined' && options.mode === 'full-screen') document.body.classList.add('is-modal-fullscreen');
    };

    const removeModal = () => {
        if (typeof document !== 'undefined' && optionsModal.mode === 'full-screen')
            document.body.classList.remove('is-modal-fullscreen');

        setOpenModal(false);
        setModalContent(null);
    };

    const centerDivOnModal = (idDiv, smooth = true) => {
        const containerScroll = document.getElementById('container-modal');
        const centeredDiv = document.getElementById(idDiv);
        if (centeredDiv) {
            const containerScrollHeight = containerScroll.clientHeight;
            const centeredDivHeight = centeredDiv.clientHeight;
            if (smooth) {
                containerScroll.scrollTo({
                    top: centeredDiv.offsetTop + centeredDivHeight / 2 - containerScrollHeight / 2,
                    behavior: 'smooth'
                });
            } else {
                containerScroll.scrollTop = centeredDiv.offsetTop + centeredDivHeight / 2 - containerScrollHeight / 2;
            }
        }
    };

    const setPlayerMutedByCCMAStorage = async () => {
        const muted = (await getStorageValue('bmplayer.3cat.muted')) === 'true';
        setMutePlayer(muted);
    };

    useEffect(() => {
        setPlayerMutedByCCMAStorage();
    }, []);

    return (
        <ModalContext.Provider
            value={{ openModal, modalContent, optionsModal, mutePlayer, setModal, removeModal, centerDivOnModal, setMutePlayer }}
            children={children}
        />
    );
};

export default ModalProvider;
