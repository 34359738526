const didomiConfig = {
    app: {
        name: 'Ccma',
        apiKey: 'ea8bee0c-fac0-4150-846a-53dc583758d0',
        vendors: {
            iab: {
                all: true,
                version: 2
            }
        }
    },
    notice: {
        position: 'popup'
    }
};

export default didomiConfig;
